import { Suspense } from 'react';
import Main from '../components/Main';
import {BrowserRouter as Router,Route, Routes} from 'react-router-dom';
import Loading from '../utils/Loading';
import Games from '../components/Games';
import ValidateToken from '../middleware';
import Profile from '../components/Profile';
import Contact from '../components/Contact';
import Changepass from '../components/Changepass';
import Register from '../components/Register';
import ForgetPassword from '../components/ForgetPassword';
import Login from '../components/Login';
import PromotionPage from '../components/PromotionPage';
const Routers = () => {
  return (
    <Router>
        <Suspense fallback={<Loading />}>
            <Routes>
                <Route path="/" element={<Main />}></Route>
                <Route path="/register" element={<Register />}></Route>
                <Route path="/login" element={<Login />}></Route>
                <Route path="/forget-password" element={<ForgetPassword />}></Route>
                <Route path="/:name/:id" element={<ValidateToken><Games /></ValidateToken>}></Route>
                <Route path="/profile" element={<ValidateToken><Profile /></ValidateToken>}></Route>
                <Route path="/change-password" element={<ValidateToken><Changepass /></ValidateToken>}></Route>
                <Route path="/contact" element={<Contact />}></Route>
                <Route path="/promotions" element={<PromotionPage />}></Route>
            </Routes>
        </Suspense>
    </Router>
  )
}

export default Routers