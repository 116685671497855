import { configureStore } from '@reduxjs/toolkit'
import authSlice from './slices/authSlice'
import callBackUserDataSlice from './slices/callBackUserDataSlice'
import langSlice from './slices/langSlice'
import userInfoSlice from './slices/userInfoSlice'
import alertSlice from './slices/alertSlice'
export const store = configureStore({
  reducer: {
     auth: authSlice,
     lang: langSlice,
     userInfo: userInfoSlice,
     refrUserInfo: callBackUserDataSlice,
     alertInfo: alertSlice
  },
})