import React,{useEffect,useState} from 'react'
import '../assets/styles/Footer.css';
import { getMethod } from '../apis';
import { useNavigate } from 'react-router-dom';
import { langChange } from '../lang';
const kpay:string = require('../assets/images/K_Pay.PNG');
const wave:string = require('../assets/images/Wave_pay.PNG');
const kbzBanking:string = require('../assets/images/Kbz_banking.png');
const ayaBanking:string = require('../assets/images/Aya_banking.png');
const cbBanking:string = require('../assets/images/Cb_banking.png');
const thaiBanking:string = require('../assets/images/Thai_banking.jpg');

const Footer = () => {
  // const [paymentType,setPaymentType] = useState([]);
  // const navigate = useNavigate();
  // useEffect(()=>{
  //       (async ()=> {
  //         const resPaymentType:any = await getMethod(`/okie/payment-providers?status=active&sortColumn=id&sortDirection=asc&app_name_id=15`);
  //         if(resPaymentType){
  //             if(resPaymentType.message === "Unauthenticated."){
  //                 localStorage.removeItem('_oki_auth');
  //                 navigate("/")
  //             };
  //             if(resPaymentType.status === "success"){
  //                 if(resPaymentType.data){
  //                   setPaymentType(resPaymentType.data);
  //                 };
  //             };
  //         };
  //       })();
  // },[]);
  return (
    <div className="container-fluid ">
        <div className="row we_accept_frame">
          <div className="col">
            <div className="container">
              <div className="row we_accept">
                <div className="col">
                <span className='text-white d-block mb-2'>{langChange.acceptPayment}</span>
                    <img src={thaiBanking} alt="" />
                    <img src={wave} alt="" />
                    <img src={kpay} alt="" />
                    <img src={kbzBanking} alt="" />
                    <img src={ayaBanking} alt="" />
                    <img src={cbBanking} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row footer_row">
          <div className="col">
            <div className="container">
              <div className="row">
                <div className="col copy_right">
                  Copyright 2023 &copy; by <span style={{color:'#186196', fontWeight:'bold'}}>Okie</span>bet
                </div>
                {/* <div className="col col-sm-6 dev_frame">
                  Join With : 
                  <FaFacebookSquare className='me-1' style={{fontSize:'1.6em'}} />
                  <FaViber style={{fontSize:'1.6em'}} />
                </div> */}
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default Footer