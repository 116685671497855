import React,{useEffect} from 'react';
import {FaViber,FaFacebookSquare,FaTelegram} from 'react-icons/fa';
import { useSelector } from 'react-redux';
import '../assets/styles/ContactCard.css';
import { Link } from 'react-router-dom';
const {langChange} = require('../lang/index');
const ContactCard = () => {
    const {value} = useSelector((state:any) => state.lang);
    useEffect(()=>{
    },[value]);
  return (
    <div className="container-fluid">
        <div className="row step_to_play_frame">
            <div className="col-12">
                <div className="container">
                    <div className="row">
                        <div className="col content_heading text_center">
                            <h1 className="heading_style">
                              <span className="text_thin text-white me-2">{langChange.contactus}</span> 
                              <span className="gold_color text-uppercase">OkieBet</span>
                            </h1>
                        </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                          <Link to="viber://chat?number=09777491444" target='_blank'>
                            <div className="step_column opacity_bg">
                              <FaViber color='#FFF' style={{fontSize: '70px', marginTop: '40px'}} />
                              <p className="step_inner_heading">Viber </p>
                            </div>
                          </Link>
                      </div>
                      <div className="col-md-4">
                        <Link to="https://www.facebook.com/profile.php?id=100091109536948&mibextid=LQQJ4d" target='_blank'>
                            <div className="step_column opacity_bg">
                                <FaFacebookSquare color='#FFF' style={{fontSize: '70px', marginTop: '40px'}} />
                                <p className="step_inner_heading">Facebook</p>
                            </div>
                        </Link>
                      </div>
                      <div className="col-md-4">
                        <Link to="https://t.me/+959777491444" target='_blank'>
                            <div className="step_column opacity_bg">
                                <FaTelegram color='#FFF' style={{fontSize: '70px', marginTop: '40px'}} />
                                <p className="step_inner_heading">Telegram</p>
                            </div>
                        </Link>
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ContactCard