import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import { useDispatch } from "react-redux";
import { alertInfoHandler } from "../store/slices/alertSlice";

export default function Popup({ status = false, photo }: any) {
  const dispatch = useDispatch();
  const [scale, setScale] = useState(0);

  useEffect(() => {
    status ? setScale(1) : setScale(0);
  }, [status]);

  return (
    <>
      <div
        className={`position-fixed w-100 h-100 d-flex justify-content-center bg-dark bg-opacity-75 align-items-center`}
        style={{
          top: 0,
          zIndex: 999,
          transform: `scale(${scale})`,
          transition: "opacity .5s ease-in",
        }}
        onClick={(e) => {
          if (e.target === e.currentTarget) {
            setScale(0);
            const data:any = {};
            dispatch(alertInfoHandler(data));
          }
        }}
      >
        <div
          className={`mx-auto px-3`}
          style={{
            transform: `scale(${scale})`,
            transition: "transform .5s ease-in-out",
          }}
        >
          <div className="float-end">
            <FiX
              className="text-white"
              style={{fontSize: '30px', cursor: 'pointer'}}
              onClick={() => {
                setScale(0);
                const data:any = {};
                dispatch(alertInfoHandler(data));
              }}
            />
          </div>
          <img className="w-full px-2 card-img" src={photo} alt="Popup" />
        </div>
      </div>
    </>
  );
}
