import React from 'react'
import Footer from '../layouts/Footer'
import Header from '../layouts/Header'
import Banner from './Banner'
import ContactCard from './ContactCard'

const Contact = () => {
  return (
    <>
        <Header />
        <Banner depositStatusCard={true} height="350px" overflowHide="hidden" bannerText={true} />
        <ContactCard />
        <Footer />
    </>
  )
}

export default Contact