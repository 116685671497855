const domain:string|undefined = process.env.REACT_APP_DOMAIN;
const node_domain:string|undefined = process.env.REACT_APP_NODE_DOMAIN;
// const domain:string|undefined = "http://192.168.1.9:8080/api";

interface getType {
    (route: string):void
}
interface getAuthType {
    (route: string,token: string):void
}
interface postType {
    (route: string,data:object,token: string):void
}
interface postNoAuthType {
    (route: string,data:object):void
}

export const getMethod: getType = async (route) => {
    try {
        const res = await fetch(`${domain}${route}`,{
            method: "GET"
        });
        const resData = await res.json();
        return resData;
    }
    catch(err){
        console.log(err);
    };
};

export const getMethodNode: getAuthType = async (route,token) => {
    try {
        const res = await fetch(`${node_domain}${route}`,{
            method: "GET",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
        });
        const resData = await res.json();
        return resData;
    }
    catch(err){
        console.log(err);
    };
};

export const getMethodAuth: getAuthType = async (route,token) => {
    try {
        const res = await fetch(`${domain}${route}`,{
            method: "GET",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
        });
        const resData = await res.json();
        return resData;
    }
    catch(err){
        console.log(err);
    };
};

export const postMethod: postType = async (route,data,token) => {
    try {
        const res = await fetch(`${domain}${route}`,{
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
                "Authorization" : `Bearer ${token}`,
            },
            body: JSON.stringify(data)
        })
        const resData = await res.json();
        return resData;
    }
    catch(err){
        console.log(err)
    }
}

export const postMethodwithoutAuth: postNoAuthType = async (route,data) => {
    try {
        const res = await fetch(`${domain}${route}`,{
            method: "POST",
            headers: {
                "accept": "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data)
        })
        const resData = await res.json();
        return resData;
    }
    catch(err){
        console.log(err);
    }
}

export const uploadPostMethod: postType = async (route,data,token) => {
    const res = {
        method: "POST",
        url: `${domain}${route}`,
        data,
        headers: {
          Accept: "application/json",
          authorization: `Bearer ${token}`,
        },
    }
    return res;
}
