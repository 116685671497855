import React, { useEffect, useRef, useState } from 'react';
import {createPortal} from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';
import SweetAlert from 'sweetalert2';
import {FiSearch} from 'react-icons/fi';
import { getMethod, getMethodAuth, postMethod } from '../apis';
import '../assets/styles/GameAll.css';
import Loading from '../utils/Loading';
// const undermaintainImg:string = require('../assets/images/maintain.png');
const imgLoader:string = require('../assets/images/loading.png');
const { LazyLoadImage } = require('react-lazy-load-image-component');
const GameAll = () => {
    const [data,setData] = useState<any>({photo: ""});
    const [loading,setLoading] = useState(true);
    const [maintain,setMaintain] = useState<any>(null);
    const [userWallet,setUserWallet] = useState(0);
    const navigate = useNavigate();
    const [gameList,setGameList] = useState([]);
    const [filterGames,setFilterGame] = useState([]);
    const mounted = useRef(false);
    const [maintainImg, setMaintainImg] = useState(false);
    const [searchText,setSearchText] = useState("");
    const [totalPage,setTotalPage] = useState(1);
    const [currentPage,setCurrentPage] = useState(1);
    const [pagi,setPagi] = useState(1);
    const [hideDownArrow,setHideDownArrow] = useState(true);
    const [imageLoad,setImageLoad] = useState(true);
    let param:any = useParams();
    const scrollRef:any = useRef();
    useEffect(()=>{
        (async ()=> {
              let res:any = await getMethod(`/providers/${param.id}`);
              if(res){
                if(res.status === "success"){
                   if(res.data){
                       setData(res.data);
                   }
                }
              }else{
                setLoading(false);
              };
        })();
    },[]);

    useEffect(()=>{
        (
            async () => {
                const getStore:any = localStorage.getItem('_oki_auth');
                const token:any = JSON.parse(getStore);
                if(token){
                    let res:any;
                    switch (token.user_role) {
                        case 'user':
                            res = await getMethodAuth('/user_info',token.token);
                            break;
                        case 'streamer':
                            res = await getMethodAuth('/streamer_info',token.token);
                        break;
                        default:
                            break;
                    }
                    if(res){
                        setLoading(false);
                        if(res.success){
                            if(res.data){
                                if(res.data.user_info){
                                    if(res.data.user_info.balance){
                                        setUserWallet(res.data.user_info.balance);
                                    }
                                }
                            };
                        };
                    };
                };
            }
        )();
    },[]);

    useEffect(()=>{
        (async () =>{
            setLoading(true);
            let res:any = await getMethod(`/providers/${param.id}`);
            if(res){
                if(res.status === "success"){
                    let resGames:any = await getMethod(`/game-lists?system_status=active&website=true&g_type=${param.name}&p_code=${res.data.name}&sortColumn=g_type&page=${pagi}&limit=500`);
                    if(resGames.status === "success"){
                        setLoading(false);
                        if(resGames.data){
                            setGameList((prev):any => {
                                return [...prev,...resGames.data]
                            });
                            setSearchText("");
                            setFilterGame([]);
                        };
                    };
                    if(resGames.meta){
                        if(resGames.meta.last_page){
                            setTotalPage(resGames.meta.last_page);
                        };
                        if(resGames.meta.current_page){
                            setCurrentPage(resGames.meta.current_page);
                        };
                    };
                };
            }else{
                setLoading(false);
            };
        })()
    },[pagi]);

    const gamesListsHandler = () => {
        if(currentPage !== totalPage){
            setPagi(currentPage + 1);
        }
        if(currentPage === totalPage - 1){
            setHideDownArrow(false);
        }else{
            setHideDownArrow(true);
        }
        // if (scrollRef.current) {
        //   const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        //   if (scrollTop + clientHeight === scrollHeight) {
            // if(currentPage !== totalPage){
            //     setPagi(currentPage + 1);
            //     setHideDownArrow(true);
            // }else{
            //     setHideDownArrow(false);
            // };
        //   }
        // }
    };

    // const gamesScrollHandler = () => {
    //     if (scrollRef.current) {
    //       const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    //       if (scrollTop + clientHeight === scrollHeight) {
    //         if(currentPage !== totalPage){
    //             setPagi(currentPage + 1);
    //             setHideDownArrow(true);
    //         }else{
    //             setHideDownArrow(false);
    //         };
    //       }
    //     }
    // };

    const searchGameHandler = (e:any) => {
        let str = e.target.value;
        setSearchText(e.target.value);
        let arr = str.split(" ");
        for (var i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        let str2 = arr.join(" ");
        let temp:any = [];
        if(gameList.length > 0){
            gameList.map((el:any) => {
                if(el.gameName.startsWith(str2)){
                    temp.push(el);
                }
            })
        };
        setFilterGame(temp);
    };

    const goGame = async (name:any,type:any,code:any,newPara:any) => {
        setLoading(true);
        const getStore:any = localStorage.getItem('_oki_auth');
        const token = JSON.parse(getStore);
        if(token){
            const data = {
                provider_name: name,
                p_code: name,
                p_type: type,
                g_code: code,
                h5: newPara
            }

                let res:any;
                switch (token.user_role) {
                    case 'user':
                        res = await postMethod(`/slot/init/`,data,token.token);
                        break;
                    case 'streamer':
                        res = await postMethod(`/slot/streamer-init/`,data,token.token);
                        break;
                    default:
                        break;
                }
                if(res){
                        setLoading(false);
                        if(res.message === "Unauthenticated."){
                            localStorage.removeItem('_oki_auth');
                            navigate('/');
                        };
                        if(res.status === 'error') {
                            SweetAlert.fire({
                                width: "330px",
                                icon: "error",
                                title: "Sorry!",
                                text: res.message === 'system error' ? "Please check your internet connection & try again!" : res.message,
                            });
                        }
                        if(res.success){
                            if(res.data){
                                if(res.data.gameUrl){
                                    setLoading(false);
                                    if(!window.open(res.data.gameUrl)) {
                                        window.location.href = res.data.gameUrl;
                                    };
                                };
                            };
                        }else{
                            if(res.data){
                                if(res.data.errCode){
                                    if(res.data.errCode === "81"){
                                        setLoading(false);;
                                        setMaintainImg(false);
                                        return setMaintain("Required Game Unit")
                                    }else{
                                        setMaintain(null);
                                        setMaintainImg(false)
                                    };
        
                                    if(res.data.errCode === "999"){
                                        setLoading(false);
                                        setMaintain(false);
                                        return setMaintainImg(true)
                                    }else{
                                        setMaintain(null);
                                        setMaintainImg(false)
                                    };
                                }
                            };
                        };
            
                }else{
                    setLoading(false);
                };
        };
    }

    const openGame =  async (name:any,type:any,code:any,h5:any) => {
        let newPara = h5 === "mobile"? 1: 0;
        goGame(name,type,code,newPara);
    };

    const getPoral:any = document.getElementById("loading");
    
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div className="container position-relative">
                        <div className="row ">
                            <div className="container ">
                                <div className="row">
                                    <div className="col ">
                                        <div className="container search_frame">
                                            <div className="row">
                                                <div className="col-6 category_title text-white" style={{paddingLeft: 0}}>
                                                    <img src={data.photo} className="mb-2" alt="" width= '70px' />
                                                </div>
                                                <div className="col-6 search_box_frame">
                                                    <div style={{
                                                        display: 'inline',
                                                        position: 'relative'
                                                    }}>
                                                        <FiSearch color='#FFF' size='20px' style={{position: 'absolute', left: 0, top: 0}}/>
                                                        <input type="text" className="search_box" value={searchText} placeholder="Search Game" onChange={searchGameHandler}/>
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                gameList.length > 1 &&
                                                <div className='row'>
                                                    <div className='col-12 p-0'>
                                                        <span className='text-white mt-2 d-block' style={{fontSize: '20px'}}>Games : {gameList.length > 0 ? gameList.length : 0}</span>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row item_row justify-content-center">
                        {
                            maintain || maintainImg ? <div
                            style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'red',
                                fontSize: '25px',
                                fontWeight: 'bolder',
                                userSelect: 'none'
                            }}
                            >{maintain || (maintainImg && <img alt="maintain" src={'undermaintainImg'} style={{marginTop: '100px', width: '100%'}} />)}</div> :
                            searchText.length > 0?
                            filterGames.length > 0 && 
                            filterGames.map((list:any) => 
                                <div key={list.id} className="col-lg-2 col-md-3 col-sm-4 p-1" id="item_colunm" onClick={() => openGame(list.p_code,list.p_type,list.g_code,list.h5)}>
                                    <div className="item_list_frame">
                                        <div>
                                            <img src={list.imgFileName} alt="" />
                                        </div>
                                        <div className="game_title">{`${list.gameName.slice(0, 10)} ...`}</div>
                                    </div>
                                </div>
                            )
                                :
                            gameList.length > 0 && 
                            gameList.map((list:any) => 
                                <div key={list.id} className="col-lg-2 col-md-3 col-sm-4 p-1" id="item_colunm" onClick={() => openGame(list.p_code,list.p_type,list.g_code,list.h5)}>
                                    <div className="item_list_frame">
                                        <div>
                                            <LazyLoadImage
                                                alt=""
                                                effect="blur"
                                                src={imageLoad ? imgLoader:list.imgFileName}
                                                afterLoad={() => setImageLoad(false)}
                                                beforeLoad={() => setImageLoad(true)}/>
                                        </div>
                                        <div className="game_title">{`${list.gameName.slice(0, 10)} ...`}</div>
                                    </div>
                                </div>
                            )
                            
                        }
                        </div>
                        {   searchText.length > 0 ?  
                            filterGames.length > 499 && <div className="scroll-down-arrow" style={{display: hideDownArrow? "block":"none"}} onClick={gamesListsHandler}></div> :
                            gameList.length > 499 && <div className="scroll-down-arrow" style={{display: hideDownArrow? "block":"none"}} onClick={gamesListsHandler}></div>
                        }  
                    </div>
                </div>
            </div>
         {
             createPortal(loading && <Loading />, getPoral)
         }
        </>
    );
}

export default GameAll