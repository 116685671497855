import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface authState {
    status: boolean
};

const initialState: authState = {
    status: false,
};

const authSlice = createSlice({
    name: 'authorize',
    initialState,
    reducers: {
        authStatus: (state,action:PayloadAction<boolean>) => {
            state.status = action.payload
        }
    }
});

export const {authStatus} = authSlice.actions;
export default authSlice.reducer;