import { langChange } from "../lang";

export default function PromotionCard({ data, event }:any) {
  return (
    <div
      className="rounded-2 p-2 w-full animate-fadein border border-dark"
      style={{ backgroundColor: "#001709" }}
    >
      <img
        className="card-img rounded-2"
        src={data.photo}
        alt="Promotion"
        style={{minHeight: '200px'}}
      />
      <p className="mt-3 mb-2 text-white text-xl text-center">
        {data.en_title}
      </p>
      <div className="d-flex justify-content-between">
        <p className="text-white fw-light">{langChange.from} - {data.from}</p>
        <p className="text-white fw-light">{langChange.to} - {data.to}</p>
      </div>
      <button
        className="py-2 px-3 w-100 btn btn-sm btn-warning mx-auto"
        onClick={event}
      >
        {langChange.see_detail}
      </button>
    </div>
  );
}
