import React from 'react';
import '../assets/styles/Loading.css';
const Loading = () => {
  return (
    <div className='loader-backdrop'>
       <div className="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>
  )
}

export default Loading