import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface LangState {
    status: boolean
};

const initialState: LangState = {
    status: true,
};

const callBackUserDataSlice = createSlice({
    name: 'refresh',
    initialState,
    reducers: {
        changeStatus: (state,action:PayloadAction<boolean>) => {
            state.status = action.payload
        }
    }
});

export const {changeStatus} = callBackUserDataSlice.actions;
export default callBackUserDataSlice.reducer;