import React, {useState,useEffect} from 'react';
import {createPortal} from 'react-dom';
import '../assets/styles/Register.css';
import { langChange } from '../lang';
import PasswordInput from '../utils/PasswordInput';
import { useSelector } from 'react-redux';
import { getMethod, getMethodAuth, postMethodwithoutAuth } from '../apis';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2'; 
import Loading from '../utils/Loading';
import { FaLock, FaTelegram, FaViber } from 'react-icons/fa';
const logo:string = require('../assets/images/sample_logo.png');

const Register = () => {
  const [loading,setLoading] = useState(false);
  const [loginName,setLoginName] = useState("");
  const [phone,setPhone] = useState("");
  const [newPass,setNewPass] = useState("");
  const [conPass,setConPass] = useState("");
  const [bankAccountNumber,setBankAccountNumber] = useState("");
  const [bankAccountName,setBankAccountName] = useState("");
  const [bankName,setBankName] = useState("");
  const [loginNameValidate,setLoginNameValidate] = useState("");
  const [newValidate,setNewValidate] = useState("");
  const [conValidate,setConValidate] = useState("");
  const [phoneValidate,setPhoneValidate] = useState("");
  const [bankAccoutNumberValidate,setBankAccoutNumberValidate] = useState("");
  const [bankAccoutNameValidate,setBankAccoutNameValidate] = useState("");
  const [bankNameValidate,setBankNameValidate] = useState("");
  const [verify,setVerify] = useState("");
  const [verifyValidate, setVerifyValidate] = useState("");
  const navigate = useNavigate();
  const {value} = useSelector((state:any) => state.lang);
  const [readStatus,setReadStatus] = useState(false);
  const [paymentType,setPaymentType] = useState([]);

  useEffect(()=>{
    (async ()=> {
      const resPaymentType:any = await getMethod(`/okie/payment-providers?status=active&sortColumn=id&sortDirection=asc&app_name_id=15`);
      if(resPaymentType){
          if(resPaymentType.status === "success"){
              if(resPaymentType.data){
                setPaymentType(resPaymentType.data);
              };
          };
      };
    })();
  },[]);

  const submitHandler = async (e:any) => {
    e.preventDefault();
    setLoading(true);
    const data = {
        name: loginName,phone,password: newPass,
        password_confirmation: conPass,
        otp: verify,
        bank_name: bankName,
        bank_acc_name: bankAccountName,
        bank_acc_no: bankAccountNumber,
        merchant_id: '69kfph2640ee325b3244'
     };
     let res:any = await postMethodwithoutAuth(`/okie/register`,data);
     if(res){
         setLoading(false);
          if(res.success){
             SweetAlert.fire({
                 icon: 'success',
                 width: 300,
                 title: "Success!",
                 text: "Register Successful!",
             }).then(res => {
                 if(res.isConfirmed){
                     setLoginName("");
                     setPhone("");
                     setVerify("");
                     setNewPass("");
                     setConPass("");
                     setBankAccountName("");
                     setBankAccountNumber("");
                     setNewValidate("");
                     setPhoneValidate("");
                     navigate("/");
                 };
             });;
          }else{
             if(res.data.error){
                 setPhoneValidate(res.data.error);
                 setNewValidate(res.data.error);
                }
                if(res.data.name){
                  setLoginNameValidate(res.data.name[0]);
               };
                if(res.data.phone){
                   setPhoneValidate(res.data.phone[0]);
                };
                if(res.data.password){
                 setNewValidate(res.data.password[0]);
                };
                if(res.data.password_confirmation){
                 setConValidate(res.data.password_confirmation[0]);
                };
                if(res.data.bank_name){
                    setBankNameValidate(res.data.bank_name[0]);
                };
                if(res.data.bank_acc_no){
                    setBankAccoutNumberValidate(res.data.bank_acc_no[0]);
                };
                if(res.data.bank_acc_name){
                    setBankAccoutNameValidate(res.data.bank_acc_name[0]);
                };
                if(res.data.otp){
                  setVerifyValidate(res.data.otp[0]);
                };
                if(typeof res.data.error === "string"){
                  setVerifyValidate(res.data.error);
                }
          };
     }else{
         setLoading(false);
     };
  }


  const getOtp = async (e:any) => {
    setVerifyValidate("");
    if(phone.length > 5 && phone.length < 12){
      let res:any = await postMethodwithoutAuth(`/getOTP`, {phone,app_name_id: 15});
      if(!res.success){
        if(res.data){
           if(res.data.phone){
              setPhoneValidate(res.data.phone[0]);
           };
        };
     }else{
       e.target.disabled = true;
       setReadStatus(true);
       e.target.innerHTML = "02:00";
       let timer:any = 60 * 2;
       let minutes:any;
       let seconds:any;
       let countDown:any = setInterval(function () {
           minutes = parseInt((timer / 60).toString(), 10);
           seconds = parseInt((timer % 60).toString(), 10);
           minutes = minutes < 10 ? "0" + minutes : minutes;
           seconds = seconds < 10 ? "0" + seconds : seconds;
           e.target.innerHTML = minutes + ":" + seconds;
   
           if (--timer < 0) {
               e.target.innerHTML = "Get OTP";
               e.target.disabled = false;
               setReadStatus(false);
               clearInterval(countDown);
           }
       }, 1000);
     };
        return res;
    }else{
        setPhoneValidate("Phone number is minium 6 and maxinum 11")
    }
  };

  useEffect(()=>{
  },[value]);
  const getPoral:any = document.getElementById("loading");
  return (
    <>
        <div className="container-fluid">
            <div className="row form_heading_row">
                <div className="col-12 text_center">
                    <Link to="/"><img src={logo} alt="" /></Link>
                    Okie<span className="bet_style">Bet</span> <span className="user_login_hd_text">User Register</span>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-6 col-lg-5 col-xl-4 login_form_frame opacity_bg">
                    <div className="form_inner_frame">
                        <form onSubmit={submitHandler}>
                            <div className="mb-5">
                                <input type="text" className="form_input_style is-invalid" value={loginName} placeholder={langChange.loginName} style={{background: 'none'}} 
                                    onChange={e => {
                                        if(e.target.value.length > 50){
                                            return false;
                                        };
                                        setLoginName(e.target.value);
                                        setLoginNameValidate("");
                                    }}
                                />
                                <div className={loginNameValidate && "invalid-feedback d-block"}>{loginNameValidate}</div>
                            </div>
                            <div className="mb-5">
                                <div style={{position: 'relative'}}>
                                    <input type="number" className="form_input_style is-invalid" style={{paddingRight: '90px',background: 'none'}} value={phone} readOnly={readStatus}  placeholder={langChange.loginPhone}
                                        onChange={e => {
                                            if(e.target.value.length > 50){
                                                return false;
                                            };
                                            setPhone(e.target.value);
                                            setPhoneValidate("");
                                        }}
                                    />
                                    <button className='btn btn-warning' type='button' style={{
                                            position: 'absolute',
                                            right: '0',
                                            top: '-10px',
                                            bottom: '0'
                                    }} onClick={getOtp}>GET OTP</button>
                                </div>
                                <div className={phoneValidate && "invalid-feedback d-block"}>{phoneValidate}</div>
                            </div>
                            <div className="mb-5">
                                <input type="number" className="form_input_style is-invalid" value={verify} placeholder="OTP Code" style={{background: 'none'}} onChange={e => {
                                    if(e.target.value.length > 6){
                                        return false;
                                    };
                                    setVerify(e.target.value);
                                    setVerifyValidate("");
                                }}/>
                                <div className={verifyValidate && "invalid-feedback d-block"}>{verifyValidate}</div>
                            </div>

                            <div className="mb-5">
                                    <select className="form_select_style is-invalid" style={{background: 'none',paddingLeft: '7px'}} onChange={e => {
                                       setBankName(e.target.value)
                                       setBankNameValidate("");
                                    }}>
                                    <option value="">{langChange.chooseBank}</option>
                                    <option value="Thai Bank(Kasikorn)">Thai Bank(Kasikorn)</option>
                                    <option value="Wave">Wave</option>
                                    <option value="Kpay">Kpay</option>
                                    <option value="KBZ Mobile Banking">KBZ Mobile Banking</option>
                                    <option value="AYA Mobile Banking">AYA Mobile Banking</option>
                                    <option value="CB Mobile Banking">CB Mobile Banking</option>
                                    {/* {
                                        paymentType.length > 0 &&
                                        paymentType.map((el:any,i:number) => 
                                            <option key={i} value={el.name}>{el.name}</option>
                                        )
                                    } */}
                                </select>
                                {
                                    bankNameValidate && <div className="invalid-feedback d-block">{bankNameValidate}</div>
                                }
                            </div>


                            <div className="mb-5">
                                <input type="text" className="form_input_style is-invalid" value={bankAccountName} placeholder={langChange.bankAccoutName} style={{background: 'none'}} onChange={e => {
                                        if(e.target.value.length > 50){
                                            return false;
                                        };
                                        setBankAccountName(e.target.value);
                                        setBankAccoutNameValidate("");
                                    }}/>
                                <div className={bankAccoutNameValidate && "invalid-feedback d-block"}>{bankAccoutNameValidate}</div>
                            </div>
                            <div className="mb-5">
                                <input type="number" className="form_input_style is-invalid" value={bankAccountNumber} placeholder={langChange.bankAccoutNumber} style={{background: 'none'}} onChange={e => {
                                        if(e.target.value.length > 50){
                                            return false;
                                        };
                                        setBankAccountNumber(e.target.value);
                                        setBankAccoutNumberValidate("");
                                    }}/>
                                <div className={bankAccoutNumberValidate && "invalid-feedback d-block"}>{bankAccoutNumberValidate}</div>
                            </div>

                            <PasswordInput 
                                value={langChange.password}
                                state={newPass}
                                setState={setNewPass}
                                validate={newValidate}
                                setValidate={setNewValidate}
                            />
                            <PasswordInput 
                                value={langChange.conpass}
                                state={conPass}
                                setState={setConPass}
                                validate={conValidate}
                                setValidate={setConValidate}
                            />
                            <button type="submit" className="btn btn-warning"> <FaLock /> &nbsp; Register</button>
                            <span className="forgot_style"><Link to="/login">Have Account? Go Login</Link></span>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 offset-md-4 ask_frame">
                    If there any problem please contact
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 offset-md-4">
                    <div className="row">
                        <div className="col-6 mb-5" style={{textAlign:'right'}}>
                            <Link to="viber://chat?number=09777491333" target='_blank'>
                                <div className='btn btn-purple text-white' style={{backgroundColor: 'purple',fontSize: '15px'}}>
                                    <FaViber style={{fontSize:'1.8em'}} /> 09777491333
                                </div>
                            </Link>
                        </div>
                        <div className="col-6 mb-5" style={{textAlign:'left'}}>
                            <Link to="https://t.me/+959777491333" target='_blank'>
                                <div className='btn btn-info text-white' style={{fontSize: '15px'}}>
                                    <FaTelegram style={{fontSize:'1.8em'}} /> 09777491333
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            createPortal(loading && <Loading />, getPoral)
        }
    </>
  )
}

export default Register;