import React, { useState } from "react";
import SweetAlert from 'sweetalert2';
import { useNavigate } from "react-router-dom";
import Loading from "../utils/Loading";
import { postMethod } from "../apis";
const { langChange } = require('../lang/index');

export default function PromotionDetail({ data, closeEvent }:any) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const applyHandler = async (id:any) => {
    const local_auth:any = localStorage.getItem('_oki_auth');
    const token = JSON.parse(local_auth);
    if(!token) {
      navigate('/login');
      return;
    }
    setIsLoading(true);
    const data = {
      user_id: token.user_id,
      promotion_id : id
    }
    const res:any = await postMethod('/promotion-like', data, token.token)
    res && setIsLoading(false);
    if (res?.status === 'success') {
      SweetAlert.fire({
      width: "330px",
      icon: "success",
      title: "Success!",
      text: "Apply promotion successful!",
      confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate("/");
          return;
        }
      });
      return;
    } else {
      SweetAlert.fire({
        width: "330px",
        icon: "error",
        title: "Sorry!",
        text: res?.message ?? 'Something wrong, Try again!',
        confirmButtonText: "Ok",
      });
      return;
    }
  }
  return (
    <>
      <div className="position-fixed w-100 h-100 d-flex align-items-center bg-dark bg-opacity-75" style={{top: 0, left: 0, zIndex: 999999 }}>
      <div className="p-2 border border-dark rounded-2 mx-2 mx-md-auto" style={{maxWidth: '720px', maxHeight: '85vh', backgroundColor: '#fff', overflow: 'hidden', overflowY: 'auto'}}>
        <div className="row">
          <div className="col-12 col-md-6">
            <img
              className="rounded-2 w-full card-img"
              src={data?.photo}
              alt="Promotion"
            />
          </div>
          <div className="col-12 col-md-6">
            <p className="my-2 text-xl font-semibold">
              {data?.en_title}
            </p>
            <p className="mb-2 text-primary">
              {data?.mm_title}
            </p>
            <p className="text-secondary" style={{overflow: 'hidden', overflowY: 'auto', maxHeight: '20vh'}}>
              {data?.description}
            </p>
            <div className="row my-3">
              <p className="col-6">{langChange.from} - {data?.from}</p>
              <p className="col-6">{langChange.to} - {data?.to}</p>
            </div>
            <div className="row">
              <div className="col-6 pe-1">
                <button className="w-100 btn btn-warning" disabled={data?.applied || isLoading} onClick={e => applyHandler(data.id)}>
                    {data?.applied ? langChange.applied : langChange.apply}
                </button>
              </div>
              <div className="col-6">
              <button className="w-100 btn btn-secondary" onClick={closeEvent}>
                {langChange.later}
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
      {
        isLoading && <Loading />
      }
    </>
  );
}
