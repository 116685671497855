import React, {useEffect, useState} from 'react'
import Footer from '../layouts/Footer'
import Header from '../layouts/Header'
import Banner from './Banner'
import Home from './Home'
import { useSelector } from 'react-redux'

const Main = () => {
  const [isLoggin,setIsLoggin] = useState(false);
  const {status} = useSelector((state:any) => state.auth);
  const {value} = useSelector((state:any) => state.userInfo);
  useEffect(()=>{
    if(value.id){
      setIsLoggin(true);
    }else{
      setIsLoggin(false);
    }
  },[status,value]);
  return (
    <>
       <Header />
       <Banner depositStatusCard={isLoggin} height={`${isLoggin? '350px': ''}`} overflowHide={`${isLoggin? 'hidden':''}`} bannerText={true} />
       <Home />
       <Footer />
    </>
  )
}

export default Main