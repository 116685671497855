import React from 'react'
import Footer from '../layouts/Footer'
import Header from '../layouts/Header'
import Banner from './Banner'
import Payment from './Payment'


const Profile = () => {
  return (
    <>
       <Header />
       <Banner depositStatusCard={false} height="250px" overflowHide="hidden" bannerText={false}/>
       <Payment />
       <Footer />
    </>
  )
}

export default Profile