import React, {useState,useEffect} from 'react';
import {createPortal} from 'react-dom';
import '../assets/styles/Register.css';
import { langChange } from '../lang';
import PasswordInput from '../utils/PasswordInput';
import { useDispatch, useSelector } from 'react-redux';
import { getMethod, postMethodwithoutAuth } from '../apis';
import { Link, useNavigate } from 'react-router-dom';
import Loading from '../utils/Loading';
import { FaTelegram, FaUnlock, FaViber } from 'react-icons/fa';
import SweetAlert from 'sweetalert2'; 
import { alertInfoHandler } from '../store/slices/alertSlice';
const logo:string = require('../assets/images/sample_logo.png');

const Login = () => {
  const [loading,setLoading] = useState(false);
  const [loginName,setLoginName] = useState("");
  const [password,setPassword] = useState("");
  const [loginNameValidate,setLoginNameValidate] = useState("");
  const [passwordValidate, setPasswordValidate] = useState("");
  const navigate = useNavigate();
  const {value} = useSelector((state:any) => state.lang);
  const [popup, setPopup] = useState<string>('');
  const [popupStatus, setPopupStatus] = useState<string>('');
  const dispatch = useDispatch();

  const loginSubmitHandler = async (e:any)=> {
    e.preventDefault();
    setLoading(true);
    const data = {
       name:loginName,password,
       app_name_id: 15,
    };
    const res:any = await postMethodwithoutAuth(`/okie/login`,data);
    if(res){
       setLoading(false);
       if(res.success){
            if(res.data){
                if(res.data.user_role === "admin"){
                    SweetAlert.fire({
                        icon: 'error',
                        width: 300,
                        title: "Error",
                        text: "Can't login with admin!",
                    })
                }else{
                    localStorage.setItem("_oki_auth", JSON.stringify(res.data));
                    const popup_data:any = {
                        popup_show: true,
                        popup_photo: popup,
                    }
                    popupStatus === 'active' && dispatch(alertInfoHandler(popup_data))
                    navigate('/');
                    setPassword("");
                    setLoginName("");
                };
            };
       }else{
          if(typeof res.message === "string"){
            SweetAlert.fire({
                icon: 'error',
                width: 300,
                title: "Error",
                text: res.message,
            })
          }
          if(res.data.error){
            setPasswordValidate(res.data.error);
          }
          if(res.data.name){
            setLoginNameValidate(res.data.name[0]);
          }
          if(res.data.password){
             setPasswordValidate(res.data.password[0]);
          };
       };
    }else{
     setLoading(false);
    }
  };

  const fetchPopup = async () => {
    const res:any = await getMethod(`/popup?app_name_id=15`)
    res?.status === 'success' && setPopup(res?.data?.popup_photo);
    res?.status === 'success' && setPopupStatus(res?.data?.status);
  }

  useEffect(() => {
    fetchPopup();
  }, [])

  useEffect(()=>{
  },[value]);
  const getPoral:any = document.getElementById("loading");
  return (
    <>
        <div className="container-fluid">
            <div className="row form_heading_row">
                <div className="col-12 text_center">
                    <Link to="/"><img src={logo} alt="" /></Link>
                    Okie<span className="bet_style">Bet</span> <span className="user_login_hd_text">User Login</span>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-6 col-lg-5 col-xl-4 login_form_frame opacity_bg">
                    <div className="form_inner_frame">
                        <form onSubmit={loginSubmitHandler}>
                            <div className="mb-5">
                                <input type="text" value={loginName} className="form_input_style is-invalid" placeholder={langChange.loginName} style={{background: 'none'}} 
                                    onChange={e => {
                                        if(e.target.value.length > 50){
                                            return false;
                                        };
                                        setLoginName(e.target.value);
                                        setLoginNameValidate("");
                                    }}
                                />
                                <div className={loginNameValidate && "invalid-feedback"}>{loginNameValidate}</div>
                            </div>
                            <PasswordInput
                                value={langChange.password}
                                state={password}
                                setState={setPassword}
                                validate={passwordValidate}
                                setValidate={setPasswordValidate}
                            />
                            <button type="submit" className="btn btn-warning"> <FaUnlock /> &nbsp; Login</button>
                            <span className="forgot_style"><Link to="/forget-password">Forget Password?</Link></span>
                        </form>
                    </div>
                    <span className='d-block text-center' style={{fontWeight: '300'}}>
                        You don't have account? <Link to="/register">Register Now</Link>
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 offset-md-4 ask_frame">
                    If there any problem please contact
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 offset-md-4">
                    <div className="row">
                        <div className="col-6" style={{textAlign:'right'}}>
                            <Link to="viber://chat?number=09777491444 " target='_blank'>
                                <div className='btn btn-purple text-white' style={{backgroundColor: 'purple',fontSize: '15px'}}>
                                    <FaViber style={{fontSize:'1.8em'}} /> 09777491444 
                                </div>
                            </Link>
                        </div>
                        <div className="col-6" style={{textAlign:'left'}}>
                            <Link to="https://t.me/+959777491444 " target='_blank'>
                                <div className='btn btn-info text-white' style={{fontSize: '15px'}}>
                                    <FaTelegram style={{fontSize:'1.8em'}} /> 09777491444 
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            createPortal(loading && <Loading />, getPoral)
        }
    </>
  )
}

export default Login;