import React, { useState,useEffect } from 'react';
import {createPortal} from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { getMethodAuth } from '../apis';
import SweetAlert from 'sweetalert2';
import '../assets/styles/Header.css';
import { langType } from '../store/slices/langSlice';
import { getUserInfo } from '../store/slices/userInfoSlice';
import Loading from '../utils/Loading';
import { authStatus } from '../store/slices/authSlice';
import { FaGift, FaUser } from 'react-icons/fa';
import AppDownload from '../components/AppDownload';
const logo:string = require('../assets/images/sample_logo.png');
const mm:string = require('../assets/images/mm.png');
const en:string = require('../assets/images/en.png');
const user:string = require('../assets/images/user.png');
const $ = require('jquery');
const {langChange} = require('../lang');
const { FaUnlockAlt, FaBars, FaHome, FaMap } = require("react-icons/fa");
const {GoSignOut} = require('react-icons/go')
const Header = () => {
    interface UserType {
        name: string;
        balance: number
    }
    const [loading,setLoading] = useState(false);
    const [userInfo,setUserInfo] = useState<UserType>({name: "",balance: 0});
    const _oki_lang:any = localStorage.getItem('_oki_lang');
    const [lang,setLang] = useState(_oki_lang);
    const [refr,setRefr] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {status} = useSelector((state:any) => state.refrUserInfo);
    const auth = useSelector((state:any) => state.auth.status);
    if(lang){
        langChange.setLanguage(lang);
    };
    const langHandler = (e:any) => {
        const type:string = e.target.getAttribute('lang-type');
        setLang(type);
        localStorage.setItem("_oki_lang", type);
        $('.lang_frame').slideToggle();
    };

    useEffect(()=>{
       dispatch(langType(lang))
    },[lang]);

    useEffect(()=>{
        (
            async () => {
                const getStore:any = localStorage.getItem('_oki_auth');
                const token:any = JSON.parse(getStore);
                if(token){
                    let res:any;
                    switch (token.user_role) {
                        case 'user':
                            res = await getMethodAuth('/user_info',token.token);
                            break;
                        case 'streamer':
                            res = await getMethodAuth('/streamer_info',token.token);
                            break;
                        default:
                            break;
                    }
                    if(res){
                        setLoading(false);
                        if(res.message === "Unauthenticated."){
                            localStorage.removeItem('_oki_auth');
                            navigate("/")
                        };
                        if(res.success){
                            if(res.data){
                                if(res.data.user_info){
                                    setUserInfo(res.data.user_info);
                                    dispatch(getUserInfo(res.data.user_info));
                                }
                            };
                        };
                    };
                };
            }
        )();
    },[refr,status]);

    const goProfileHandler = async() => {
        const getStore:any = localStorage.getItem('_oki_auth');
        const token:any = JSON.parse(getStore);
        if(token){
            navigate('/profile');
        }else{
            SweetAlert.fire({
                icon: 'error',
                width: 300,
                title: "Fail",
                text: "Login please!",
            });
        };
    };

    const logoutHandler = () => {
        setLoading(true);
        const getStore = localStorage.getItem('_oki_auth');
        if(getStore){
            localStorage.removeItem('_oki_auth');
            navigate('/');
            setLoading(false);
            setRefr(!refr);
            dispatch(authStatus(!auth));
        };
    };

    useEffect(() =>{
        $(document).ready(function(){
            $('#lang').click(function(){
                $('.lang_frame').slideToggle();
            });
            $('#bars').click(function(){
                $('#mobile_left_panel').animate({width:'toggle'}); //edit code
            });
            $('#closeModal').click();
        });
    },[]);

    const chunk = (num:any) => {
        let str = num.toString().split('.');
        if (str[0].length >= 4) {
            str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
        }
        if (str[1] && str[1].length >= 5) {
            str[1] = str[1].replace(/(\d{3})/g, '$1 ');
        }
        return str.join('.');
    };

    const getPoral:any = document.getElementById("loading");
  return (
    <>
        <header className="container-fluid">
            {/* Menu Bar */}
            <div className="row menu_bar " id="navbar">
                <div className="col-12">
                    <div className="container">
                        <div className="row text_white">
                            <div className="col-md-2 col-sm-4">
                                    <Link to="/" style={{whiteSpace: 'nowrap', display: 'flex'}}>
                                        <div className="logo_frame">
                                                <img src={logo} alt="" />
                                        </div>
                                        <div className="logo_text">
                                            <span className="okie">Okie</span><span className="bet">Bet</span>
                                        </div>
                                    </Link>
                            </div>
                            <div className={`${localStorage.getItem('_oki_auth')?'col-md-7':'col-md-5'}`} style={{whiteSpace: 'nowrap'}}>
                                <header className="header">
                                    <nav>
                                        <ul className="menu-items">
                                            <li>
                                                <Link to="/" className="menu-item">
                                                    {langChange.home}
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="/promotions" className="menu-item">
                                                    {langChange.promotions}
                                                </Link>
                                            </li>
                                            {/* {
                                                localStorage.getItem('_oki_auth') &&
                                                <>
                                                    <li>
                                                        <Link to="/profile" className="menu-item">
                                                            {langChange.deposit}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <Link to="/profile" className="menu-item">
                                                            {langChange.withdraw}
                                                        </Link>
                                                    </li>
                                                </>
                                            } */}
                                            {
                                                localStorage.getItem('_oki_auth') &&
                                                <>
                                                    <li>
                                                        <Link to="/contact" className="menu-item">
                                                            {langChange.contact}
                                                        </Link> 
                                                    </li>
                                                    <li>
                                                        <Link to="/change-password" className="menu-item">
                                                            {langChange.changepass}
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <div className="menu-item logout-div" onClick={logoutHandler}>
                                                            {langChange.logout}
                                                        </div>
                                                    </li>
                                                </>
                                            }
                                        </ul>
                                    </nav>
                                </header>
                            </div>
                            <div className='col-md-1 d-flex justify-content-center align-items-center pb-5'>
                                <AppDownload />
                            </div>
                            <div className={`${localStorage.getItem('_oki_auth')?'col-md-2 profile_right_login':'col-md-4 profile_right_logout'}`}>
                                    {
                                        localStorage.getItem('_oki_auth') ?
                                        <button className="user_profile" onClick={goProfileHandler}>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <img alt="user" src={user} />
                                                <div>
                                                    <span className='d-block'>{`${userInfo.name.slice(0, 4)} ...`}</span>
                                                    <span className='d-block'>{chunk(userInfo.balance)}</span>
                                                </div>
                                            </div>
                                        </button>:
                                        <>
                                            <Link to="/login" className='ms-2' style={{whiteSpace: 'nowrap'}}>
                                                <FaUnlockAlt size="20px" className="me-1"/>
                                                {langChange.login}
                                            </Link>
                                            <Link to="/register" className='ms-2' style={{whiteSpace: 'nowrap'}}>
                                                <FaUser size="20px" className="me-1"/>
                                                {langChange.register}
                                            </Link>
                                        </>
                                    }
                                        &nbsp;
                                        &nbsp; 
                                        <div className='d-inline-block position-relative'>
                                            <img src={ lang? lang ==='en'?en:mm : en} alt="" id="lang" style={{width: '30px', cursor: 'pointer',marginBottom: `${localStorage.getItem('_oki_auth')?'30px': '0'}`    }} />
                                            <div className="lang_frame">
                                                <img src={mm} lang-type="mm" alt="" style={{width: '30px'}} onClick={langHandler}/> 
                                                    <br/>
                                                <img src={en} lang-type="en" alt="" style={{width: '30px'}} onClick={langHandler}/> 
                                            </div>
                                        </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" id="mobile_menu_frame">
                <div className="col">
                    <div className="row">
                        <div className="col-1 bar_icon">
                            <FaBars style={{fontSize:'1.5em'}} id="bars" />
                        </div>
                        <div className="col-5 mobile_logo">
                            <div className="row justify-content-center">
                            <div className="col">
                                <Link to="/">
                                        <div className="inner_img">
                                            <img src={logo} alt="" style={{width: '40px'}}/>
                                        </div>
                                        <div className="inner_text">
                                            <span className="okie">Okie</span><span className="bet">Bet</span>
                                        </div>
                                </Link>
                            </div>
                            </div>
                        </div>
                        <div className='col-2 d-flex justify-content-end align-items-center pt-2'>
                            <AppDownload />
                        </div>
                        <div className="col-4 text_right" style={{
                            fontSize:'14px',
                            fontWeight: '300',
                            paddingTop: localStorage.getItem('_oki_auth')? '10px':'16px'
                        }}>
                                {
                                    localStorage.getItem('_oki_auth') ?
                                    <button className="user_profile" onClick={goProfileHandler}>
                                            <div className='d-flex justify-content-center align-items-center'>
                                                <img alt="user" src={user} />
                                                <div>
                                                    <span className='d-block'>{`${userInfo.name.slice(0, 4)} ...`}</span>
                                                    <span className='d-block'>{chunk(userInfo.balance)}</span>
                                                </div>
                                            </div>
                                    </button>:
                                    <>
                                        <Link to="/login" className='ms-2' style={{whiteSpace: 'nowrap'}}>
                                            <FaUnlockAlt size="20px" className="me-1"/>
                                            {langChange.login}
                                        </Link>
                                    </>
                                }
                        </div>
                    </div>
                </div>
            </div>
            <div className="row" id="mobile_left_panel">
                <div className="col panel_list_title">
                        <img src={mm} lang-type="mm" alt="" style={{width: '30px',marginRight: '10px'}} onClick={langHandler}/>    
                        <img src={en} lang-type="en" alt="" style={{width: '30px'}} onClick={langHandler}/>
                </div>
                <div className="col">
                    <ul>
                        <Link to="/">
                            <li>
                                <FaHome color="#e7b012" className="me-1" style={{fontSize:'1.4em'}} />
                                &nbsp;{langChange.home}
                            </li>
                        </Link>
                        <Link to="/promotions">
                            <li>
                                <FaGift color="#e7b012" className="me-1" style={{fontSize:'1.4em'}} />
                                &nbsp;{langChange.promotions}
                            </li>
                        </Link>
                        {
                            !localStorage.getItem('_oki_auth') &&
                            // <>
                            //     <Link to="/profile">
                            //         <li>
                            //             <FaWallet className="me-1" color="#e7b012" style={{fontSize:'1.4em'}} />
                            //             &nbsp;{langChange.deposit}
                            //         </li>
                            //     </Link> 
                            //     <Link to="/profile">
                            //         <li>
                            //             <FaWallet className="me-1" color="#e7b012" style={{fontSize:'1.4em'}} />
                            //             &nbsp;{langChange.withdraw}
                            //         </li>
                            //     </Link>
                            // </>:
                            <Link to='/register'>
                                <li>
                                    <FaUser className="me-1" color="#e7b012" style={{fontSize:'1.4em'}} />
                                        {langChange.register}
                                </li>
                            </Link>
                        }
                        {
                            localStorage.getItem('_oki_auth') &&
                            <>
                                <Link to="/contact">
                                    <li>
                                        <FaMap className="me-1" color="#e7b012" style={{fontSize:'1.4em'}} />
                                        &nbsp;{langChange.contact}
                                    </li>
                                </Link>
                                <Link to="/change-password">
                                    <li>
                                        <FaUnlockAlt className="me-1" color="#e7b012" style={{fontSize:'1.4em'}} />
                                        &nbsp;{langChange.changepass}
                                    </li>
                                </Link>
                                <li>
                                    <GoSignOut className="me-1" color="#e7b012" style={{fontSize:'1.4em'}} />
                                    <div className="d-inline" onClick={logoutHandler}>
                                        &nbsp;{langChange.logout}
                                    </div>
                                </li>
                            </>
                        }
                    </ul>
                </div>
            </div>
        </header>
        {
            createPortal(loading && <Loading />, getPoral)
        }
    </>
  )
}

export default Header