import { createSlice } from '@reduxjs/toolkit';

const AlertInfoSlice = createSlice({
    name: 'alert-info',
    initialState: {
        alertVal: {},
    },
    reducers: {
        alertInfoHandler: (state:any, action:any) => {
            state.alertVal = action.payload;
        },
    },
});

export const { alertInfoHandler } = AlertInfoSlice.actions;
export default AlertInfoSlice.reducer;