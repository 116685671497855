import React from 'react'
import Footer from '../layouts/Footer';
import Header from '../layouts/Header';
import Banner from './Banner';
import GameList from './GameList';

const Games = () => {
  return (
    <>
       <Header />
       <Banner depositStatusCard={true} height="350px" overflowHide="hidden"  bannerText={true}/>
       <GameList />
       <Footer />
    </>
  );
};

export default Games;