import React, {useState,useEffect} from 'react';
import {createPortal} from 'react-dom';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import '../assets/styles/Changepass.css';
import { langChange } from '../lang';
import PasswordInput from '../utils/PasswordInput';
import { useSelector } from 'react-redux';
import { postMethod } from '../apis';
import { Link, useNavigate } from 'react-router-dom';
import SweetAlert from 'sweetalert2'; 
import Loading from '../utils/Loading';
import { FaTelegram, FaUnlock, FaViber } from 'react-icons/fa';
const logo:string = require('../assets/images/sample_logo.png');

const Changepass = () => {
  const [loading,setLoading] = useState(false);
  const [oldPass,setOldPass] = useState("");
  const [newPass,setNewPass] = useState("");
  const [conPass,setConPass] = useState("");
  const [oldValidate,setOldValidate] = useState("");
  const [newValidate,setNewValidate] = useState("");
  const [conValidate,setConValidate] = useState("");
  const navigate = useNavigate();
  const {value} = useSelector((state:any) => state.lang);

  const submitHandler = async (e:any) => {
    e.preventDefault();
    setLoading(true);
    const data = {
        old_password: oldPass,
        password: newPass,
        password_confirmation: conPass
    };
    const getStore:any = localStorage.getItem('_oki_auth');
    const token = JSON.parse(getStore);
    if(token){
        let res:any = await postMethod('/change-password',data, token.token);
        if(res){
            setLoading(false);
            if(res.message === "Unauthenticated."){
                localStorage.removeItem('_oki_auth');
                navigate('/');
            }
             if(res.success){
                SweetAlert.fire({
                    icon: 'success',
                    width: 300,
                    title: "Success",
                    text: "Password changed successfully",
                }).then(res => {
                    if(res.isConfirmed){
                        localStorage.removeItem('_oki_auth');
                        navigate('/');
                        setConPass("");
                        setOldPass("");
                    };
                });;
             }else{
                if(res.errors){
                    if(res.errors.password){
                        setNewValidate(res.errors.password[0]);
                    };
                    if(res.errors.old_password){
                        setOldValidate(res.errors.old_password[0]);
                    };
                    if(res.errors.password_confirmation){
                        setConValidate(res.errors.password_confirmation[0]);
                    };
                };
                if(res.data){
                    if(res.data.error){
                        SweetAlert.fire({
                            icon: 'error',
                            width: 300,
                            title: "Error",
                            text: res.data.error,
                        });
                    }
                }
             };
        }else{
            setLoading(false);
        };
    };
  }
  useEffect(()=>{
  },[value]);
  const getPoral:any = document.getElementById("loading");
  return (
    <>
        <Header />
        <div className="container-fluid">
            <div className="row form_heading_row">
                <div className="col-12 text_center">
                    <Link to="/"><img src={logo} alt="" /></Link>
                    Okie<span className="bet_style">Bet</span> <span className="user_login_hd_text">{langChange.changepass}</span>
                </div>
            </div>
            <div className="row justify-content-center">
                <div className="col-md-6 col-lg-5 col-xl-4 login_form_frame opacity_bg">
                    <div className="form_inner_frame">
                        <form onSubmit={submitHandler}>
                            <PasswordInput 
                                value={langChange.oldpass}
                                state={oldPass}
                                setState={setOldPass}
                                validate={oldValidate}
                                setValidate={setOldValidate}
                            />
                            <PasswordInput 
                                value={langChange.newpass}
                                state={newPass}
                                setState={setNewPass}
                                validate={newValidate}
                                setValidate={setNewValidate}
                            />
                            <PasswordInput 
                                value={langChange.conpass}
                                state={conPass}
                                setState={setConPass}
                                validate={conValidate}
                                setValidate={setConValidate}
                            />
                            <button type="submit" className="btn btn-warning"> <FaUnlock /> &nbsp; Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 offset-md-4 ask_frame">
                    If there any problem please contact
                </div>
            </div>
            <div className="row">
                <div className="col-md-4 offset-md-4">
                    <div className="row">
                        <div className="col-6" style={{textAlign:'right'}}>
                            <Link to="viber://chat?number=09777491333" target='_blank'>
                                <div className='btn btn-purple text-white' style={{backgroundColor: 'purple',fontSize: '15px'}}>
                                    <FaViber style={{fontSize:'1.8em'}} /> 09777491333
                                </div>
                            </Link>
                        </div>
                        <div className="col-6" style={{textAlign:'left'}}>
                            <Link to="https://t.me/+959777491333" target='_blank'>
                                <div className='btn btn-info text-white' style={{fontSize: '15px'}}>
                                    <FaTelegram style={{fontSize:'1.8em'}} /> 09777491333
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        {
            createPortal(loading && <Loading />, getPoral)
        }
    </>
  )
}

export default Changepass;