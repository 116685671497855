import Footer from '../layouts/Footer'
import Header from '../layouts/Header'
import Banner from './Banner'
import ContactCard from './ContactCard'
import Promotions from './Promotions'

const PromotionPage= () => {
  return (
    <>
        <Header />
        <Banner depositStatusCard={true} height="350px" overflowHide="hidden" bannerText={true} />
        <Promotions />
        <Footer />
    </>
  )
}

export default PromotionPage