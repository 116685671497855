import React, {useEffect} from 'react'
import {useSelector} from 'react-redux';
import '../assets/styles/Step.css'
import { Link } from 'react-router-dom';
const suitcase:string = require('../assets/images/20_suitcase-money.png');
const {langChange} = require('../lang/index');
const Step = () => {
  const {value} = useSelector((state:any) => state.lang);
  useEffect(()=>{
  },[value])
  return (
        <div className="row step_to_play_frame">
            <div className="col-12">
                <div className="container">
                    <div className="row">
                        <div className="col content_heading text_center">
                            <h1 className="heading_style">
                              <span className="text_thin text-white me-2">{langChange.step}</span> 
                              <span className="gold_color text-uppercase">{langChange.casino}</span>
                            </h1>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                    {/* col-md-4 */}
                      <div className="col-12">
                          <Link to="/register" style={{cursor: 'default'}}>
                              <div className="step_column opacity_bg">
                                <img src={require('../assets/images/3_gambler.svg').default} alt="" />
                                <p className="step_inner_heading">{langChange.registerToday}</p>
                              </div>
                          </Link>
                      </div>
                      {/* <div className="col-md-4">
                        <div className="step_column opacity_bg">
                          <img src={suitcase} alt="" />
                            <p className="step_inner_heading">{langChange.depositMoney}</p>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="step_column opacity_bg">
                          <img src={require('../assets/images/22_poker-card-hand-.svg').default} alt="" />
                            <p className="step_inner_heading">{langChange.playNow}</p>
                        </div>
                      </div> */}
                    </div>
                </div>
            </div>
        </div>
  )
}

export default Step