import React,{useEffect,useState} from 'react';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';
import {useNavigate } from 'react-router-dom';
import { getMethod } from '../apis';
import '../assets/styles/GameTypes.css';
import SweetAlert from 'sweetalert2';
import {FaArrowRight} from 'react-icons/fa';
import Loading from '../utils/Loading';
const {langChange} = require('../lang/index');
const all:string = require('../assets/images/all.png');
const GameTypes = () => {
  const [loading,setLoading] = useState(true);
  const {value} = useSelector((state:any) => state.lang);
  const [allActive, setAllActive] = useState<any>("all");
  const [active, setActive] = useState(null);
  const [data,setData] = useState([]);
  const [providers,setProviders] = useState([]);
  const [allProviders,setAllProviders] = useState([]);
  const navigate = useNavigate();
  
  useEffect(()=>{
    (async ()=> {
          let res:any = await getMethod(`/provider-connect-lists?status=active&website=true`);
          if(res){
            setLoading(false);
            if(res.status === "success"){
               if(res.data){
                 setData(res.data);
                 setAllActive('all');
                 setActive(null);
               }
            }
         }else{
            setLoading(false);
         }
    })();
  },[]);

  useEffect(()=>{
    (async ()=> {
          let res:any = await getMethod(`/providers?status=active&website=true`);
          if(res){
            setLoading(false);
            setActive(null)
            setAllActive('all');
            setProviders([]);
            if(res.status === "success"){
               if(res.data){
                setAllProviders(res.data);
               }
            }
          }else{
             setLoading(false);
          };
    })();
   },[]);

  const providersHandler = (type:any):void => {
      setProviders(type.types)
      setLoading(false);
      setAllActive(null);
      setActive(type.name);
      setAllProviders([]);
  };
  const providersAllHandler = () => {
    setLoading(true);
    (async ()=> {
          let res:any = await getMethod(`/providers?status=active&website=true`);
          if(res){
            setLoading(false);
            setActive(null)
            setAllActive('all');
            setProviders([]);
            if(res.status === "success"){
               if(res.data){
                setAllProviders(res.data);
               }
            }
          }else{
             setLoading(false);
          };
    })();
   };

   const gotoGame = (value:any) => {
    const token = localStorage.getItem('_oki_auth');
    if(token){
      if(value){
        navigate(`/${value.type?value.type.toLowerCase(): "all"}/${value.provider_id?value.provider_id:value.id}`);
      };
    }else{
      SweetAlert.fire({
        icon: 'error',
        width: 300,
        title: langChange.fail,
        text: langChange.loginFirst,
      });
    };
   };

   const slider:any = document.querySelector('.own_slider');
    if(slider){
      let mouseDown = false;
      let startX:any, scrollLeft:any;
      let startDragging = function (e:any) {
          mouseDown = true;
          startX = e.pageX - slider.offsetLeft;
          scrollLeft = slider.scrollLeft;
      };
          let stopDragging = function (event:any) {
          mouseDown = false;
      };
      
      slider.addEventListener('mousemove', (e:any) => {
      e.preventDefault();
      if(!mouseDown) { return; }
          const x = e.pageX - slider.offsetLeft;
          const scroll = x - startX;
          slider.scrollLeft = scrollLeft - scroll;
      });
      
      slider.addEventListener('mousedown', startDragging, false);
      slider.addEventListener('mouseup', stopDragging, false);
      slider.addEventListener('mouseleave', stopDragging, false);
    }

  useEffect(()=>{
  },[value]);
  const getPoral:any = document.getElementById("loading");
  return (
    <>
        <div className="row">
          <div className="col">
            <div className="container position-relative mt-2">
              <div className='right-scroll'>
                   <FaArrowRight className='right-icon' />
              </div>
              <div className="row own_slider category_row" style={{borderRadius: '10px'}}>
                <div className={`col-3 col-md-2 col-sm-3 category_col ${allActive === "all" && 'active'}`} onClick={providersAllHandler}>
                    <img src={all} alt="" />
                    <p>All</p>
                </div>
                {
                  data.length > 0 &&
                  data.map((type:any,i:number) => 
                  <div key={i} className={`col-3 col-md-2 col-sm-3 category_col ${active === type.name && 'active'}`} onClick={()=> providersHandler(type)}>
                      <img src={`https://docs-chail.sgp1.digitaloceanspaces.com/${type.photo}`} alt="" />
                      <p>{type.name}</p>
                  </div>
                    // <div className={`tab ${active === type.name && 'active'}`} key={i} onClick={()=> providersHandler(type)}>
                    //   <img src={`https://docs-chail.sgp1.digitaloceanspaces.com/${type.photo}`} alt="provider" />
                    //   <span>{type.name}</span>
                    // </div>
                  )
                }
              </div>
              <div className="row item_row justify-content-center mt-3">
                  {
                      allActive === "all" ? 
                      allProviders.length > 0 &&
                      allProviders.map((provider:any,index:number) =>   
                        <div className="col-lg-2 col-md-3 col-sm-4 p-1" id="item_colunm" key={index} onClick={()=>gotoGame(provider)}>
                            <div className="item_list_frame">
                                <div>
                                    <img src={provider.photo} alt="" />
                                </div>
                                <div className="game_title">{provider.full_name}</div>
                            </div>
                        </div>
                      ):
                      providers.length > 0 &&
                      providers.map((provider:any,index:number) => 
                          <div className="col-lg-2 col-md-3 col-sm-4 p-1" id="item_colunm" key={index} onClick={()=>gotoGame(provider)}>
                              <div className="item_list_frame">
                                  <div>
                                      <img src={provider.provider && provider.provider.photo} alt="" />
                                  </div>
                                  <div className="game_title">{provider.provider && provider.provider.full_name}</div>
                              </div>
                          </div>
                      )
                  }
              </div>
            </div>
          </div>
        </div>
        {
          createPortal(loading && <Loading />, getPoral)
        }
    </>
  )
}

export default GameTypes