import React,{useEffect,useState} from 'react';
import {createPortal} from 'react-dom';
import { getMethod } from '../apis';
import '../assets/styles/Banner.css';
import Loading from '../utils/Loading';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import BannerText from './BannerText';

const Banner = ({height,overflowHide,depositStatusCard,bannerText}:{height:any,overflowHide: any,depositStatusCard: boolean,bannerText:boolean},) => {
  const [loading,setLoading] = useState(true);
  const [data,setData] = useState([]);
  const usrInfo = useSelector((state:any) => state.userInfo.value);
  const chunk = (num:any) => {
      let str = num.toString().split('.');
      if (str[0].length >= 4) {
          str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
      }
      if (str[1] && str[1].length >= 5) {
          str[1] = str[1].replace(/(\d{3})/g, '$1 ');
      }
      return str.join('.');
  };
    useEffect(()=>{
        (async ()=> {
            let res:any = await getMethod(`/banners?app_name_id=15`);
            if(res){
              setLoading(false);
              if(res.status === "success"){
                 if(res.data){
                    setData(res.data);
                 };
              };
            }else{
                setLoading(false);
            };
      })();
  },[]);
  const getPoral:any = document.getElementById("loading");
  return (
    <>
        <div className="container-fluid">
            <div className="row opacity_bg slider" style={{minHeight:'250px', position: 'relative'}}>
              <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="carousel" style={{maxHeight: height && height,overflow: overflowHide && overflowHide ,backgroundColor:"rgba(0, 0, 0, 0.6)"}}>
                <div className="carousel-indicators">
                  {
                      data.length > 0 &&
                      data.map((el:any,i:number) => 
                      <button key={i} type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to={i} aria-current={i === 0 ? 'true': 'false'} aria-label={`Slide ${i + 1}`} className={`${i === 0 ? 'active':''}`}></button>
                      )
                  }
                </div>
                <div className="carousel-inner">
                  {
                      data.length > 0 &&
                      data.map((el:any,i:number) => 
                        <div className={`carousel-item ${i === 0 && 'active'}`} key={i}>
                          <img src={el.photo} className={`d-block ${depositStatusCard ? 'slide-img': 'slide-img-profile' }`} style={{height: '100%',objectFit: 'contain'}} alt="..." />
                        </div>
                      )
                  }
                </div>
                {/* <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                  <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                  <span className="carousel-control-next-icon" aria-hidden="true"></span>
                  <span className="visually-hidden">Next</span>
                </button> */}
              </div>
              {
                bannerText &&
                <BannerText />
              }
              {
                depositStatusCard &&
                <Link to="/profile">
                  <div className="profile-dashboard-frame"> 
                      <div className="row ">
                          <div className="price_freame mb-3">
                              <span className="price-show d-block">{chunk(usrInfo.balance?usrInfo.balance: 0)}</span>
                              <span className="mmk">MMK</span>
                          </div>
                      </div>
                      <div className="row small-font row-padding row-bg">
                          {/* <div className="col-6 text-black font-size-ref">Total Play Amount<br/> Chip</div> */}
                          <div className="col-6 text-black font-size-ref">Player Name</div>
                          <div className="col-6 text-black text-bold">{usrInfo.name}</div>
                      </div>
                      <div className="row">
                          <div className="col-12 show-deopsit-withdraw">
                              Deposit & Withdraw
                          </div>
                      </div>
                  </div>
              </Link>
              }
            </div>
        </div>
        {
          createPortal(loading && <Loading />, getPoral)
        }
    </>
  )
}

export default Banner