import LocalizedStrings from 'react-localization';

export const langChange = new LocalizedStrings({
    mm:{
        login: "အကောင့်ဝင်ပါ",
        register: "အကောင့်သစ်ဖွင့်ပါ",
        loginTitle: "အကောင့်ဝင်ရန်",
        loginPhone: "ဖုန်းနံပါတ်",
        loginName: "အကောင့်နာမည်",
        loginPassword: "လျှို့ဝှက်နံပါတ်",
        home:"ပင်မစာမျက်နှာ",
        contact: "ဆက်သွယ်ရန်",
        contactus: "ဆက်သွယ်ရန်",
        loginContactText: "အခက်အခဲရှိလျှင် ဆက်သွယ်ရန်",
        step: "ကစားရန်အဆင့်",
        casino: "ကာစီနို",
        registerToday: "ဒီနေ့အကောင့်ဖွင့်ပါ",
        depositMoney: "ငွေဖြည့်ပါ",
        playNow: "ဂိမ်းကစားပါ",
        deposit: "ငွေသွင်း",
        withdraw: "ငွေထုတ်",
        depositForm: "ငွေသွင်း",
        withdrawForm: "ငွေထုတ်",
        depositAmount: "ငွေသွင်း ပမာဏ",
        withdrawAmount: "ငွေထုတ် ပမာဏ",
        transactionNo: "လုပ်ငန်းစဉ် နံပါတ်",
        accountNo: "အကောင့် နံပါတ်",
        confirm: "အတည်ပြုပါ",
        waiting: "စောင့်ဆိုင်းနေသည် ...",
        alreadyDeposit: "ငွေဖြည့်ထားသည်။ ကျေးဇူးပြု၍ အတည်ပြုမှုကိုစောင့်ပါ။",
        alreadyWithdraw: "ငွေထုတ်ထားသည်။ ကျေးဇူးပြု၍ အတည်ပြုမှုကိုစောင့်ပါ။",
        success: "အောင်မြင်သည်။",
        depositSuccess: "ငွေဖြည့်မှု အောင်မြင်သည်။",
        withdrawSuccess: "ငွေထုတ်မှု အောင်မြင်သည်။",
        fail: "မအောင်မြင်ပါ။",
        loginFirst: "ကျေးဇူးပြု၍ အကောင့်ဝင်ပါ။",
        logout: "ထွက်မည်",
        choosePayment: "ငွေဖြည့်နည်းစနစ်ရွေးပါ",
        bankAccountNumber: "ဘဏ်အကောင့်နံပါတ်",
        bankAccountName: "ဘဏ်အကောင့်နာမည်",
        chooseBank: "ဘဏ်အမျိုးအစားရွေးပါ",
        changepass: "စကားဝှက်ပြောင်းရန်",
        registerAcc: "အကောင့်သစ်ဖွင့်ရန်",
        oldpass: "စကားဝှက်အဟောင်း",
        newpass: "စကားဝှက်အသစ်",
        conpass: "စကားဝှက်အတည်ပြု",
        password: "စကားဝှက်",
        forgetPass: "စကားဝှက်မေ့နေလား?",
        resetPass: "စကားဝှက် ပြန်ရှာရန်",
        acceptPayment: "ငွေပေးချေမှုများလက်ခံသည်",
        bankName: "ဘဏ်နာမည်",
        bankAccoutName: "ဘဏ်အကောင့်နာမည်",
        bankAccoutNumber: "ဘဏ်အကောင့်နံပါတ်",
        promotion: 'ပရိုမိုးရှင်း',
        promotions: 'ပရိုမိုးရှင်းများ',
        see_detail: 'အသေးစိတ်ကြည့်ရန်',
        from: 'မှ',
        to: 'အထိ',
        apply: 'ရယူမည်။',
        applied: 'ရယူပြီးပါပြီ။',
        later: 'နောက်မှ',
    },
    en:{
        login: 'Login',
        register: "Register",
        loginTitle: "User Login",
        loginPhone: "Phone",
        loginName: "User Name",
        loginPassword: "Password",
        home:"Home",
        contact: "Contact",
        contactus: "Contact Us",
        loginContactText: "If there any problem please contact",
        step: "Step To Play",
        casino: "Casino",
        registerToday: "Register Today",
        depositMoney: "Deposit Money",
        playNow: "Play Game",
        deposit: "Deposit",
        withdraw: "Withdraw",
        depositForm: "Deposit Form",
        withdrawForm: "Withdraw Form",
        depositAmount: "Deposit Amount",
        withdrawAmount: "Withdraw Amount",
        transactionNo: "Slip Code",
        accountNo: "Account No",
        confirm: "Confirm",
        waiting: "Waiting ...",
        alreadyDeposit: "You have already deposit.Please wait approve!",
        alreadyWithdraw: "You have already withdraw.Please wait approve!",
        success: "Success!",
        depositSuccess: "Deposit Successful!",
        withdrawSuccess: "Withdraw Successful!",
        fail: "Fail!",
        loginFirst: "Login please!",
        logout: "Logout",
        choosePayment: "Choose Payment Type",
        bankAccountNumber: "Bank Account Number",
        bankAccountName: "Bank Account Name",
        chooseBank: "Choose Bank",
        changepass: "Change Password",
        registerAcc: "Register Account",
        oldpass: "Old Password",
        newpass: "New Password",
        conpass: "Confirm Password",
        password: "Password",
        forgetPass: "Forget Password?",
        resetPass: "Reset Password",
        acceptPayment: "We Accept Payment",
        bankName: "Bank Name",
        bankAccoutName: "Bank Account Name",
        bankAccoutNumber: "Bank Account Number",
        promotion: 'Promotion',
        promotions: 'Promotions',
        see_detail: 'See Detail',
        from: 'From',
        to: 'To',
        apply: 'Apply',
        applied: 'Applied',
        later: 'Later',
    }
});