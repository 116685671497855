import React from 'react'
import GameAll from './GameAll'

const GameList = () => {
  return (
    <div className="container-fluid">
       <GameAll />
    </div>
  )
}

export default GameList