import { FiDownload } from "react-icons/fi";

let deferredPrompt: any;

window.addEventListener("beforeinstallprompt", (event) => {
  event.preventDefault();
  deferredPrompt = event;
  console.log('hello')
  deferredPrompt.userChoice.then((choiceResult: any) => {
    if (choiceResult.outcome === "accepted") {
      // document.querySelector(".pwa-button")?.classList.toggle('flex')
      // document.querySelector(".pwa-button")?.classList.toggle('hidden')
    }
  });
  // document.querySelector(".pwa-button")?.classList.toggle('flex')
  // document.querySelector(".pwa-button")?.classList.toggle('hidden')
});

export default function AppDownload() {
  const triggerPWAInstallation = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    } else {
      alert(
        'To install this app, open the browser menu and select "Add to Home Screen".'
      );
    }
  };
  return (
    <button className="btn btn-sm btn-warning" style={{whiteSpace: 'nowrap', marginLeft: '25px'}} onClick={triggerPWAInstallation}>
      App
      <FiDownload className="ms-2" />
    </button>
  );
}
