import { createSlice } from '@reduxjs/toolkit';

const userInfoSlice = createSlice({
    name: 'user-info',
    initialState: {
        value: {},
    },
    reducers: {
        getUserInfo: (state:any, action:any) => {
            state.value = action.payload;
        }
    },
});

export const {getUserInfo} = userInfoSlice.actions;
export default userInfoSlice.reducer;