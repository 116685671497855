import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "../assets/styles/ContactCard.css";
import PromotionCard from "./PromotionCard";
import { getMethod, getMethodAuth } from "../apis";
import PromotionDetail from "./PromotionDetailCard";
import Loading from "../utils/Loading";
const { langChange } = require("../lang/index");

const Promotions = () => {
  const { value } = useSelector((state: any) => state.lang);
  useEffect(() => {}, [value]);
  const [promotions, setPromotions] = useState<any>([]);
  const [promoCheck, setPromoCheck] = useState<any>([]);
  const [promoDetail, setPromoDetail] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const res:any = await getMethod('/promotions');
      res && setLoading(false);
      if (res?.status === 'success') {
        setPromotions(res?.data);
      }
      const local_auth:any = localStorage.getItem('_oki_auth');
      const token = JSON.parse(local_auth);
      if (local_auth) {
        setLoading(true);
        const check:any = await getMethodAuth(`/promotion-check?user_id=${token?.user_id}`, token.token);
        check && setLoading(false);
        if (check?.status === 'success') {
          setPromoCheck(check?.data)
          
        }
      }
    })()
  }, [])

  useEffect(() => {
    if (promoCheck?.length > 0 && promotions.length > 0) {
      const result = promotions?.map((promo1:any) => {
        const find = promoCheck?.find((promo2:any) => promo2.promotion_id === promo1.id);
        if (find) {
          return { ...promo1, applied: true };
        } else {
          return { ...promo1, applied: false };
        }
      });
      setPromotions(result);
    }
  }, [promoCheck])

  return (
    <>
    <div className="container-fluid">
      <div className="row step_to_play_frame">
        <div className="col-12">
          <div className="container">
            <div className="row">
              <div className="col content_heading text_center">
                <h1 className="heading_style">
                  <span className="text_thin text-white me-2">
                    {langChange.promotions}
                  </span>
                </h1>
              </div>
            </div>
            <div className="row">
              {
                promotions?.length > 0 &&
                promotions.map((item: any, index: number) => 
                <div key={`promo_key_${item.id}_${index}`} className="col-12 col-md-6 col-lg-4 p-0 pb-2 p-md-2">
                  <PromotionCard data={item} event={() => setPromoDetail(item)} />
                </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
    {
      promoDetail?.en_title &&
      <PromotionDetail data={promoDetail} closeEvent={() => setPromoDetail({})} />
    }
    {
      loading && <Loading />
    }
    </>
  );
};

export default Promotions;
