import React, {useEffect} from 'react'
import GameTypes from './GameTypes'
import Step from './Step'
import { useSelector } from 'react-redux'
import ContactCard from './ContactCard'
import Popup from './Popup'

const Home = () => {
  const {status} = useSelector((state:any) => state.auth);
  const { alertVal } = useSelector((state:any) => state.alertInfo);
  useEffect(()=>{},[status]);
  return (
    <>
    <Popup status={alertVal?.popup_show ?? false} photo={alertVal?.popup_photo} />
    <div className="container-fluid">
      {
        !localStorage.getItem('_oki_auth') &&
        <Step />
      }
        <GameTypes />
      {
        !localStorage.getItem('_oki_auth') &&
        <>
          <ContactCard />
        </>
      }
    </div>
    </>
  )
}

export default Home