import React,{useEffect, useState, useRef} from 'react'
import {createPortal} from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getMethodAuth, getMethodNode, postMethod, uploadPostMethod } from '../apis';
import SweetAlert from 'sweetalert2'; 
import '../assets/styles/Payment.css'
import { changeStatus } from '../store/slices/callBackUserDataSlice';
import Loading from '../utils/Loading';
import axios from 'axios';
import TableScroll from '../utils/TableScroll';
const {IoCopyOutline} = require('react-icons/io5')
const user:string = require('../assets/images/user.png');
const {langChange} = require('../lang/index');
const {format} = require("date-fns");

const Payment = () => {
  const [loading,setLoading] = useState(true);
  const [historyData,setHistoryData] = useState([]);
  const [historyWithdrawData,setHistoryWithdrawData] = useState([]);
  const [paymentType,setPaymentType] = useState([]);
  const [pendingDeposit,setPendingDeposit] = useState(false);
  const [pendingWithdraw,setPendingWithdraw] = useState(false);
  const [refr,setRefr] = useState(true);
  const usrInfo = useSelector((state:any) => state.userInfo.value);
  const {value} = useSelector((state:any) => state.lang);
  const {status} = useSelector((state:any) => state.refrUserInfo);
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [photo,setPhoto] = useState("");
  const [transaction, setTransaction] = useState("");
  const [phone, setPhone] = useState("");
  const [bank,setBank] = useState("");
  const [bankName,setBankName] = useState("");
  const [bankValidate,setBankValidate] = useState("");
  const [bankNameValidate, setBankNameValidate] = useState("");
  const [amountValidate,setAmountValidate] = useState("");
  const [transactionValidate, setTransactionValidate] = useState("");
  const [phoneValidate, setPhoneValidate] = useState("");
  const [photoValidate,setPhotoValidate] = useState("");
  const [userid,setUserid] = useState("");
  const dispatch = useDispatch();
  const paymentRef:any = useRef();
  const [paymentActiveId,setPaymentActiveId] = useState("");
  const photoRef:any = useRef();
  const [depositTabActive,setDepositTabActive] = useState(true);
  const [withdrawTabActive,setWithdrawTabActive] = useState(false);
  const [bettingTabActive,setBettingTabActive] = useState(false);
  const [history, setHistory] = useState([]);

  useEffect(()=>{
    const getStore:any = localStorage.getItem('_oki_auth');
    const token:any = JSON.parse(getStore);
        (async ()=> {
            if(token){
                setUserid(token.user_id);
                const resPaymentType:any = await getMethodAuth(`/payment-providers?status=active&sortColumn=id&sortDirection=asc&app_name_id=15`, token.token);
                const resDeposit:any = await getMethodAuth(`/slot/deposits?user_id=${token.user_id}&limit=20`, token.token);
                const resWithdraw:any = await getMethodAuth(`/slot/withdraws?user_id=${token.user_id}&limit=20`, token.token);
                const depositPendingStatus:any = await getMethodAuth(`/slot/deposits?user_id=${token.user_id}&status=Pending`, token.token);
                const withdrawPendingStatus:any = await getMethodAuth(`/slot/withdraws?user_id=${token.user_id}&status=Pending`, token.token);

                if(resPaymentType){
                    setLoading(false);
                    if(resPaymentType.message === "Unauthenticated."){
                        localStorage.removeItem('_oki_auth');
                        navigate("/")
                    };
                    if(resPaymentType.status === "success"){
                        if(resPaymentType.data){
                          setPaymentType(resPaymentType.data);
                        };
                    };
                } else {
                    setLoading(false)
                }
                
                if(resDeposit){
                    if(resDeposit.message === "Unauthenticated."){
                        localStorage.removeItem('_oki_auth')
                        navigate("/")
                    }
                    if(resDeposit.status === "success"){
                        if(resDeposit.data){
                            setHistoryData(resDeposit.data);
                        };
                    };
                }
                if(resWithdraw){
                    if(resWithdraw.message === "Unauthenticated."){
                        localStorage.removeItem('_oki_auth')
                        navigate("/")
                    }
                    if(resWithdraw.status === "success"){
                        if(resWithdraw.data){
                            setHistoryWithdrawData(resWithdraw.data);
                        };
                    };
                }

                if(depositPendingStatus){
                    if(depositPendingStatus.message === "Unauthenticated."){
                        localStorage.removeItem('_oki_auth')
                        navigate('/')
                    }
                    if(depositPendingStatus.data){
                        if(depositPendingStatus.data.length > 0){
                            setPendingDeposit(true);
                        }else{
                            setPendingDeposit(false);
                        };
                    };
                }
                if(withdrawPendingStatus){
                    if(withdrawPendingStatus.message === "Unauthenticated."){
                        localStorage.removeItem('_oki_auth')
                        navigate('/')
                    }
                    if(withdrawPendingStatus.data){
                        if(withdrawPendingStatus.data.length > 0){
                            setPendingWithdraw(true);
                        }else{
                            setPendingWithdraw(false);
                        };
                    };
                }
            }else{
                setLoading(false);
            }
        })();
  },[refr]);

  useEffect(()=>{
  },[value]);

  const chunk = (num:any) => {
    let str = num.toString().split('.');
    if (str[0].length >= 4) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
        str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  };

  const depositHandler = async (e:any) => {
    e.preventDefault();
    setLoading(true);
    if(pendingDeposit){
        SweetAlert.fire({
            icon: 'warning',
            width: 300,
            title: langChange.waiting,
            text: langChange.alreadyDeposit
        });
    }else{
        try {
            const formData = new FormData();
            formData.append("user_id", userid);
            formData.append("payment_provider_id", paymentActiveId);
            formData.append("amount", amount);
            formData.append("transaction_no", transaction);
            formData.append("slip_image", photo);
            formData.append("initial_balance", usrInfo.balance);
            const getStore:any = localStorage.getItem('_oki_auth');
            const token:any = JSON.parse(getStore);
            const fetchData: any = await uploadPostMethod('/okie/deposits',formData, token.token)
            const response:any = await axios.request(fetchData);
            if(response.data.status === "success"){
                setLoading(false);
                SweetAlert.fire({
                    icon: 'success',
                    width: 300,
                    title: langChange.success,
                    text: langChange.depositSuccess
                }).then((res:any) => {
                    if(res.isConfirmed){
                        setRefr(!refr);
                        navigate('/profile');
                        setPhoto("");
                        setAmount("");
                        setTransaction("");
                        setPaymentActiveId("");
                        photoRef.current.value = "";
                    };
                });
            };
        } catch (error:any) {
            if(error.response){
                setLoading(false);
                if(error.response.status === 403){
                    SweetAlert.fire({
                        icon: 'error',
                        width: 300,
                        title: 'Fail',
                        text: error.response.data.message,
                    })
                }
                if(error.response.data){
                    if(error.response.data.message === "Unauthenticated."){
                        localStorage.removeItem('_oki_auth');
                        navigate("/")
                    };
                };
                if(error.response.data.message){
                        if(typeof error.response.message === "string"){
                            setAmountValidate(error.response.message);
                        }
                        if(error.response.data.message.payment_provider_id){
                            SweetAlert.fire({
                                icon: 'error',
                                width: 300,
                                title: 'Fail',
                                text: langChange.choosePayment,
                            });
                        };
                        if(error.response.data.message.amount){
                            setAmountValidate(error.response.data.message.amount[0]);
                        };
                        if(error.response.data.message.slip_image){
                            setPhotoValidate(error.response.data.message.slip_image[0]);
                        };
                        if(error.response.data.message.transaction_no){
                            setTransactionValidate(error.response.data.message.transaction_no[0]);
                        };
    
                };
            };
        }
    }
    
  };
  const withdrawHandler = async (e:any) => {
      e.preventDefault();
      setLoading(true);
        if(pendingWithdraw){
            setLoading(false);
            SweetAlert.fire({
                icon: 'warning',
                width: 300,
                title: langChange.waiting,
                text: langChange.alreadyWithdraw,
            });
        }else{
            const withdrawData = {
                user_id : userid,
                amount,
                name: bankName,
                payment_provider_id: bank,phone,
                initial_balance:usrInfo.balance
             };
             const getStore:any = localStorage.getItem('_oki_auth');
             const token:any = JSON.parse(getStore);
              if(token){
                const res:any = await postMethod(`/okie/withdraws`,withdrawData, token.token);
                if(res){
                    setLoading(false);
                    if(res.message === "Unauthenticated."){
                        localStorage.removeItem('_oki_auth')
                        navigate('/')
                    }
                    if(res.status === "success"){
                        document.getElementById("closeWithdrawModal")?.click();
                        SweetAlert.fire({
                            icon: 'success',
                            width: 300,
                            title: langChange.success,
                            text: langChange.withdrawSuccess
                        }).then(res => {
                            if(res.isConfirmed){
                                setRefr(!refr);
                                navigate('/profile');
                                setAmount("");
                                setBankName("");
                                setPhone("");
                                setBank("");
                                dispatch(changeStatus(!status));
                            };
                        });
                    }else{
                        setLoading(false);
                        if(typeof res.message === "string"){
                            setAmountValidate(res.message);
                        }
                        if(res.message.amount){
                            setAmountValidate(res.message.amount[0]);
                        };
                        if(res.message.phone){
                            setPhoneValidate("Bank account field is required.");
                        };
                        if(res.message.payment_provider_id){
                            setBankValidate("Payment type field is required.");
                        }
                        if(res.message.name){
                            setBankNameValidate("Bank account name field is required.");
                        }
                    }
                }else{
                    setLoading(false);
                };
              }else{
                    setLoading(false);
              };
        };
  };

  const paymentTypeHandler = (e:any) => {
      setPaymentActiveId(e.target.id);
  };

  async function copyToClipboard(copyMe:any) {
        await navigator.clipboard.writeText(copyMe);
        SweetAlert.fire({
            icon: 'success',
            width: 300,
            title: 'Success',
            text: "Copy to Clipboard",
        })
  };

  const depositTabHandler = () => {
    setDepositTabActive(true);
    setWithdrawTabActive(false);
    setBettingTabActive(false);
    setAmount("");
    setPhoto("");
    setTransaction("");
    setPhone("");
    setBank("");
    setBankName("");
    setBankValidate("");
    setBankNameValidate("");
    setAmountValidate("");
    setTransactionValidate("");
    setPhoneValidate("");
    setPhotoValidate("");
  };

  const withdrawTabHandler = () => {
    setBettingTabActive(false)
    setDepositTabActive(false);
    setWithdrawTabActive(true);
    setAmount("");
    setPhoto("");
    setTransaction("");
    setPhone("");
    setBank("");
    setBankName("");
    setBankValidate("");
    setBankNameValidate("");
    setAmountValidate("");
    setTransactionValidate("");
    setPhoneValidate("");
    setPhotoValidate("");
  };

  const bettingTabHandler = () => {
    setBettingTabActive(true);
    setDepositTabActive(false);
    setWithdrawTabActive(false);
    setAmount("");
    setPhoto("");
    setTransaction("");
    setPhone("");
    setBank("");
    setBankName("");
    setBankValidate("");
    setBankNameValidate("");
    setAmountValidate("");
    setTransactionValidate("");
    setPhoneValidate("");
    setPhotoValidate("");
  };

  useEffect(()=>{
    (async()=>{
        const token = localStorage.getItem("_oki_auth");
        let currentDate = format(new Date(), "yyyy-MM-dd");
        if (token) {
        setLoading(true);
        const deToken:any = JSON.parse(token);
        const res:any = await getMethodNode(`/betting-history?user_id=${deToken.user_id}&start_date=${currentDate}&end_date=${currentDate}`,deToken.token);
        
        if (res.status === "success") {
            setLoading(false);
            setHistory(res.data);
        }
        }
    })()
  },[]);

  const getPoral:any = document.getElementById("loading");
  return (
    <>
        <div className="container-fluid">
            <div className="row profile_frame">
                <div className="col-12">
                    <div className="container">
                        <div className="row ">
                            <div className="container ">

                                <div className="row ">
                                    <div className="col-md-2 offset-md-2 user_photo_frame">
                                        <img src={user} alt="" />
                                    </div>
                                    <div className="col-md-8 profile_name_frame">
                                        <h1 className="">
                                            <span className="thin">{usrInfo.name} - ( {usrInfo.id} )</span> 
                                            {/* <br/> <span className="thin">ID - {usrInfo.id}</span> */}
                                            <br/> <span className="">{chunk(usrInfo.balance ? usrInfo.balance: 0)} MMK</span>
                                        </h1>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="container" >
                                        <div className="row">
                                            <div className="col-lg-10 offset-lg-1">
                                                <div className="row profile_main_row">
                                                    <div className="col-md-3 profile_left_style">
                                                        <ul className="profile_list_style">
                                                            <li className={`${depositTabActive && 'list_active_bg'}`} onClick={depositTabHandler}>
                                                                <svg className='me-2' stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg"><rect width="416" height="288" x="48" y="144" fill="none" strokeLinejoin="round" strokeWidth="32" rx="48" ry="48"></rect><path fill="none" strokeLinejoin="round" strokeWidth="32" d="M411.36 144v-30A50 50 0 00352 64.9L88.64 109.85A50 50 0 0048 159v49"></path><path d="M368 320a32 32 0 1132-32 32 32 0 01-32 32z"></path></svg>
                                                                Deposit
                                                            </li>
                                                            <li className={`${withdrawTabActive && 'list_active_bg'}`} onClick={withdrawTabHandler}>
                                                                <svg className='me-2' stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg"><path fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M304 336v40a40 40 0 01-40 40H104a40 40 0 01-40-40V136a40 40 0 0140-40h152c22.09 0 48 17.91 48 40v40m64 160l80-80-80-80m-192 80h256"></path></svg>
                                                                Withdraw
                                                            </li>
                                                            <li className={`${bettingTabActive && 'list_active_bg'}`} onClick={bettingTabHandler}>
                                                                <svg className='me-2' stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 512 512" height="25px" xmlns="http://www.w3.org/2000/svg"><title>Order_history</title><g id="Order_history"><path d="M251.2305,448H70.7735a150.4432,150.4432,0,0,0,32.2656-93.5391V64H381.4336V217.1719a8,8,0,0,0,16,0V56a7.9979,7.9979,0,0,0-8-8H95.0391a7.9979,7.9979,0,0,0-8,8V354.4609a134.7126,134.7126,0,0,1-39.711,95.8829A7.9993,7.9993,0,0,0,52.9844,464H251.2305a8,8,0,0,0,0-16Z"/><path d="M333.9961,148.7656h-183.52a8,8,0,0,0,0,16h183.52a8,8,0,0,0,0-16Z"/><path d="M341.9961,210.9688a7.9979,7.9979,0,0,0-8-8h-183.52a8,8,0,1,0,0,16h183.52A7.9979,7.9979,0,0,0,341.9961,210.9688Z"/><path d="M262.2305,265.1719a7.9979,7.9979,0,0,0-8-8H150.4766a8,8,0,0,0,0,16H254.2305A7.9979,7.9979,0,0,0,262.2305,265.1719Z"/><path d="M150.4766,311.375a8,8,0,1,0,0,16h65.2539a8,8,0,0,0,0-16Z"/><path d="M436.7266,287.4609A103.32,103.32,0,0,0,261.7905,342.873l-6.3569-6.3574a7.9991,7.9991,0,0,0-11.3125,11.3125L262.53,366.2363a8.026,8.026,0,0,0,11.3232,0l18.4126-18.4082a7.9991,7.9991,0,0,0-11.3125-11.3125l-1.8169,1.8164a87.3378,87.3378,0,1,1,22.6568,84.0664,7.9991,7.9991,0,0,0-11.3125,11.3125,103.413,103.413,0,0,0,146.2461-146.25Z"/><path d="M363.6055,291.32a7.9979,7.9979,0,0,0-8,8v61.2656a8.115,8.115,0,0,0,2.3506,5.6641l28.9931,28.9922A7.9991,7.9991,0,0,0,398.2617,383.93l-26.6562-26.6563V299.32A7.9979,7.9979,0,0,0,363.6055,291.32Z"/></g></svg>
                                                                Betting History
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="col-md-9 deposit_profile_right">
                                                        {
                                                            depositTabActive && 
                                                            <>
                                                                <div className="row payment_acc_row">
                                                                    {
                                                                        paymentType.length > 0 &&
                                                                        paymentType.map((payment:any,i) => 
                                                                        <div key={i} className='col-md-4'>
                                                                            <label htmlFor={payment.id} className={`d-block mb-2 ${Number(paymentActiveId) === payment.id && 'activePaymentC'}`}>
                                                                                <input type="radio" id={payment.id} className="d-none" name="payment" onClick={(e:any) => paymentTypeHandler(e)} ref={paymentRef}/>

                                                                                <div className="payment_acc_inner">
                                                                                    <span className="payment_img">
                                                                                        <img src={payment.photo} alt="payment"/>
                                                                                    </span><br/>
                                                                                    <span className="payment_text">
                                                                                        {payment.name}<br/>
                                                                                    <b className="text_black">{payment.phone} 
                                                                                        <IoCopyOutline className="ms-1 text-white" title="copy" size="18px" onClick={(e:any) => {e.preventDefault()
                                                                                        copyToClipboard(payment.phone)
                                                                                        }}/>
                                                                                    </b>
                                                                                    </span>
                                                                                </div>
                                                                            </label>
                                                                        </div>
                                                                        )
                                                                    }    
                                                                </div>
                                                                <div className="row deposit_form_row">
                                                                    <div className="col-12" >
                                                                        <div style={{borderTop: '1px solid #000',padding:'10px 0'}}>
                                                                            <form onSubmit={depositHandler}>
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <label htmlFor="">Deposit Amount</label>
                                                                                        <input type="number" value={amount} placeholder={langChange.depositAmount} className="form_input_style_profile" id="" style={{background: 'none'}}  onChange={(e:any) => {
                                                                                            setAmount(e.target.value);
                                                                                            setAmountValidate("");
                                                                                        }}/>
                                                                                        <div className={amountValidate && "invalid-feedback d-block"}>{amountValidate}</div>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <label htmlFor="">Slip Code</label>
                                                                                        <input type="number" value={transaction} className="form_input_style_profile" id="" placeholder={langChange.transactionNo} style={{background: 'none'}}  onChange={(e:any) => {
                                                                                            setTransaction(e.target.value);
                                                                                            setTransactionValidate("");
                                                                                        }}/>
                                                                                        <div className={transactionValidate && "invalid-feedback d-block"}>{transactionValidate}</div>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <label htmlFor="">Payment Screenshot</label>
                                                                                        <input type="file" ref={photoRef} className="form_input_style_profile" id="" placeholder="" style={{background: 'none'}} onChange={(e:any) => {
                                                                                            setPhoto(e.target.files[0]);
                                                                                            setPhotoValidate("");
                                                                                        }}/>
                                                                                        <div className={photoValidate && "invalid-feedback d-block"}>{photoValidate}</div>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <label htmlFor="">&nbsp;</label>
                                                                                        <button className="btn btn-warning text-white w-100 btn-style" type="submit"> Confirm </button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row table_list_row">
                                                                    <div className="col-12 table-responsive">
                                                                        <table className="table table-striped table-hover">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>Payment</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Status</th>
                                                                                    <th>Date</th>
                                                                                    <th>Remark</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    historyData.length > 0 &&
                                                                                    historyData.map((el:any,i:number) =>
                                                                                        <tr key={i} className={`${el.status === "Pending" && 'bg-warning'}`}>
                                                                                            <td style={{
                                                                                                color: el.status === "Pending" ? "#FFF": "#a9a5a5"
                                                                                            }}>{i + 1}</td>
                                                                                            <td style={{
                                                                                                color: el.status === "Pending" ? "#FFF": "#a9a5a5"
                                                                                            }}>{el.payment_provider_name? el.payment_provider_name: "--"}</td>
                                                                                            <td style={{textAlign: 'right',color: el.status === "Pending" ? "#FFF": "#a9a5a5"}}>{el.amount? chunk(el.amount): 0}</td>
                                                                                            <td style={{
                                                                                                color: (el.status === "Approved" && "green") ||
                                                                                                    (el.status === "Rejected" && "red") ||
                                                                                                    (el.status === "Pending" && "#FFF") || ""
                                                                                            }}>{el.status? el.status: "--"}</td>
                                                                                            <td style={{
                                                                                                color: el.status === "Pending" ? "#FFF": "#a9a5a5"
                                                                                            }}>{el.created_at}</td>
                                                                                            <td style={{
                                                                                                color: (el.status === "Approved" && "green") ||
                                                                                                (el.status === "Rejected" && "red") || "#a9a5a5"
                                                                                            }}>{el.remark? el.remark: el.status === "Approved" ?"Success": "--"}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        {
                                                            withdrawTabActive &&
                                                            <>
                                                                <div className="row deposit_form_row">
                                                                    <div className="col-12" >
                                                                        <div style={{padding:'10px 0'}}>
                                                                            <form onSubmit={withdrawHandler}>
                                                                                <div className="row">
                                                                                    <div className="col-md-6">
                                                                                        <label htmlFor="">Withdraw Amount</label>
                                                                                        <input type="number" value={amount} placeholder={langChange.withdrawAmount} className="form_input_style_profile" id="" style={{background: 'none'}}  onChange={(e:any) => {
                                                                                            setAmount(e.target.value);
                                                                                            setAmountValidate("");
                                                                                        }}/>
                                                                                        <div className={amountValidate && "invalid-feedback d-block"}>{amountValidate}</div>
                                                                                    </div>

                                                                                    <div className="col-md-6">
                                                                                        <label htmlFor="">Account No</label>
                                                                                        <input type="number" value={phone} className="form_input_style_profile" id="" placeholder={langChange.bankAccountNumber} style={{background: 'none'}}  onChange={(e:any) => {
                                                                                            setPhone(e.target.value);
                                                                                            setPhoneValidate("");
                                                                                        }}/>
                                                                                        <div className={phoneValidate && "invalid-feedback d-block"}>{phoneValidate}</div>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <label htmlFor="">Payment</label>
                                                                                        <select className='form_input_style_profile' style={{background: 'none'}} value={bank} onChange={(e:any) => {
                                                                                            setBank(e.target.value);
                                                                                            setBankValidate("");
                                                                                        }}>
                                                                                            <option value="">{langChange.chooseBank}</option>
                                                                                            {
                                                                                                paymentType.length > 0 &&
                                                                                                paymentType.map((el:any,i:number) => 
                                                                                                    <option key={i} value={el.id}>{el.name}</option>
                                                                                                )
                                                                                            }
                                                                                        </select>
                                                                                        <div className={bankValidate && "invalid-feedback d-block"}>{bankValidate}</div>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <label htmlFor="">Account Name</label>
                                                                                        <input type="text" value={bankName} className="form_input_style_profile" id="" placeholder={langChange.bankAccountName} style={{background: 'none'}}  onChange={(e:any) => {
                                                                                            setBankName(e.target.value);
                                                                                            setBankNameValidate("");
                                                                                        }}/>
                                                                                        <div className={bankNameValidate && "invalid-feedback d-block"}>{bankNameValidate}</div>
                                                                                    </div>
                                                                                    <div className="col-md-6">
                                                                                        <label htmlFor="">&nbsp;</label>
                                                                                        <button className="btn btn-warning text-white w-100 btn-style" type="submit"> Confirm </button>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row table_list_row">
                                                                    <div className="col-12 table-responsive">
                                                                        <table className="table table-striped table-hover">
                                                                            <thead>
                                                                                <tr>
                                                                                    <th>#</th>
                                                                                    <th>Payment</th>
                                                                                    <th>Amount</th>
                                                                                    <th>Status</th>
                                                                                    <th>Date</th>
                                                                                    <th>Remark</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {
                                                                                    historyWithdrawData.length > 0 &&
                                                                                    historyWithdrawData.map((el:any,i:number) =>
                                                                                        <tr key={i} className={`${el.status === "Pending" && 'bg-warning'}`}>
                                                                                            <td style={{
                                                                                                color: el.status === "Pending" ? "#FFF": "#a9a5a5"
                                                                                            }}>{i + 1}</td>
                                                                                            <td style={{
                                                                                                color: el.status === "Pending" ? "#FFF": "#a9a5a5"
                                                                                            }}>{el.payment_provider_name? el.payment_provider_name: "--"}</td>
                                                                                            <td style={{textAlign: 'right',color: el.status === "Pending" ? "#FFF": "#a9a5a5"}}>{el.amount? chunk(el.amount): 0}</td>
                                                                                            <td style={{
                                                                                                color: (el.status === "Approved" && "green") ||
                                                                                                    (el.status === "Rejected" && "red") ||
                                                                                                    (el.status === "Pending" && "#FFF") || ""
                                                                                            }}>{el.status? el.status: "--"}</td>
                                                                                            <td style={{
                                                                                                color: el.status === "Pending" ? "#FFF": "#a9a5a5"
                                                                                            }}>{el.created_at}</td>
                                                                                            <td style={{
                                                                                                color: (el.status === "Approved" && "green") ||
                                                                                                (el.status === "Rejected" && "red") || "#a9a5a5"
                                                                                            }}>{el.remark? el.remark: el.status === "Approved" ?"Success": "--"}</td>
                                                                                        </tr>
                                                                                    )
                                                                                }
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        }
                                                        {
                                                            bettingTabActive &&
                                                            <div className="row table_list_row_history">
                                                                <div className='col-12'>
                                                                    <div className="table-responsive">
                                                                        <TableScroll>
                                                                            <table className="table table-striped table-hover">
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">#</th>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">Provider</th>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">Bet</th>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">Payout</th>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">Profit</th>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">Game Name</th>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">Ref No.</th>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">Bet Time</th>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">Play Time</th>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">Bet Type</th>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">Bet Status</th>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">Win/Lose</th>
                                                                                        <th style={{whiteSpace: 'nowrap'}} className="text-center">Status</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {
                                                                                        history.length > 0?
                                                                                        history.map((user:any,i:number) =>
                                                                                        <tr key={user.id} style={{ verticalAlign: 'middle' }}>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-center">{++i}.</td>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-center">{user.site}</td>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-end">{user.bet?.toLocaleString('en-us')}</td>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-end">{user.payout?.toLocaleString('en-us')}</td>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-end">{user.win_lose_amount ? chunk(user.win_lose_amount)?.toLocaleString('en-us') : 0}</td>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-center">{user.game_name ? user.game_name : '--'}</td>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-center">{user.ref_no}</td>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-center">{user.start_time}</td>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-center">{user.match_time}</td>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-center">{user.bet_type}</td>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-center">
                                                                                                <span className={`badge bg-${user.bet_status === "Accept" ? "info" : user.bet_status === "END" ? "success" : "danger"}`}>{user.bet_status}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-center">
                                                                                                <span className={`badge bg-${user.win_lose === "LOSE" ? "danger" : user.win_lose === "WIN" ? "success" : "info"}`}>{user.win_lose}
                                                                                                </span>
                                                                                            </td>
                                                                                            <td style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} className="text-center">
                                                                                                <span className={`badge bg-${user.status === "Waiting" ? "info" : "dark"}`}>{user.status}
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                        )
                                                                                        :
                                                                                        <tr>
                                                                                            <td className="text-center" style={{whiteSpace: 'nowrap',color: "#a9a5a5"}} colSpan={13}>No found record!</td>
                                                                                        </tr>
                                                                                    }
                                                                                </tbody>
                                                                            </table>
                                                                        </TableScroll>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
            createPortal(loading && <Loading />, getPoral)
        }
    </>
  )
}

export default Payment