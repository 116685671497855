import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

interface LangState {
    value: string
};

const initialState: LangState = {
    value: "",
};

const langSlice = createSlice({
    name: 'lang',
    initialState,
    reducers: {
         langType: (state,action:PayloadAction<string>) => {
             state.value = action.payload
         }
    }
});

export const {langType} = langSlice.actions;
export default langSlice.reducer;