import React,{useState} from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi';

interface propsTypes {
    state: string,
    setState: any,
    validate: string,
    setValidate: any,
    value: string
}

const PasswordInput = (config:  propsTypes) => {
    const [isVisible, setVisible] = useState(false);
    const toggle = () => {
        setVisible(!isVisible);
    };
  return (
    <div className="mb-5">
        <div className='password-group'>
            <input type={!isVisible ? "password" : "text"} value={config.state} className="form_input_style" id="" placeholder={config.value} style={{background: 'none'}} onChange={(e:any) => {
                if(e.target.value.length > 8){
                    return false;
                };
                config.setState(e.target.value);
                config.setValidate("");
            }}/>
            <span className='toggle-eyes' onClick={toggle}>
                {isVisible ? <FiEye /> : <FiEyeOff />}
            </span>
        </div>
        <div className={config.validate && "invalid-feedback d-block"}>{config.validate}</div>
    </div>
  )
}

export default PasswordInput