import {Navigate} from "react-router-dom"
import SweetAlert from 'sweetalert2';
const ValidateToken = ({children}:{children:any}):any => {
    const token = localStorage.getItem('_oki_auth');
    if(token){
        return children;
    }else{
        SweetAlert.fire({
            icon: 'error',
            width: 300,
            title: "Fail",
            text: "Login please!",
        });
        return <Navigate to="/" />;
    };
};

export default ValidateToken