import React from 'react';
import Routers from './routers';
document.addEventListener("wheel", function(event){
  if(document.activeElement.type === "number"){
      document.activeElement.blur();
  }
});
function App() {
  return (
     <Routers />
  );
}

export default App;
