import React,{useEffect,useState} from 'react'
import Marquee from "react-fast-marquee";
import { getMethod } from '../apis';
import '../assets/styles/BannerText.css';
const BannerText = () => {
  const [message,setMessage] = useState("မင်္ဂလာပါ");
  useEffect(() => {
    (async ()=> {
      let res:any = await getMethod(`/caption?app_name_id=15`);
            if(res){
              if(res.status === "success"){
                if(res.data){
                  if(res.data.caption){
                    setMessage(res.data.caption);
                  };
                };
              };
            };
      })();
  },[]);
  return (
    <div className="row ad_text_frame">
        <div className="col">
            <Marquee 
                gradient={false}
                speed={50}
            >
                {message} ... &nbsp;
            </Marquee>
        </div>
    </div>
  )
}

export default BannerText